import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppButton} from "shared/UI/UIKit/Button";

type ModalFooterProps = {
  submit: () => void;
  close: () => void;
  emiasId?: string | null;
}

export const ModalFooter = (props: ModalFooterProps) => {
  return (
    <AppFlex>
      {
        props.emiasId ? (
          <AppButton onClick={props.close}>
            Закрыть
          </AppButton>
        ) : (
          <AppButton onClick={props.submit}>
            Заказать
          </AppButton>
        )
      }
    </AppFlex>
  );
};