import type {AppTableSorter} from "../../../ui/types/sorter.types";
import type {AppTableChangeEvent} from "../../../ui/types/event.types";

export const getSort = (sorters: AppTableSorter[]): AppTableChangeEvent["sort"] => {
  if (!sorters.length) {
    return null;
  }

  return sorters
    .filter(item => !!item.order)
    .map(item => {
      return `${item.order === "ascend" ? "+" : "-"}${item.dataIndex.join(".")}`;
    })[0];
};