import {AppForm, AppFormItem, formRules} from "shared/UI/UIKit/Form";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppSelect} from "shared/UI/UIKit/Select";
import {AppButton} from "shared/UI/UIKit/Button";
import type {KeyboardEvent} from "react";
import type {FilterFormProps} from "./FilterForm.types";
import {FILTER_FORM_NAMES} from "../../constants";
import {getOperatorOptions} from "./FilterForm.utils";
import styles from "./FilterForm.module.scss";

export const FilterForm = <T,>(props: FilterFormProps<T>) => {
  const {children, filterType, form, onSubmit, onReset} = props;

  const onKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.key === "Enter") {
      e.stopPropagation();
      onSubmit();
    }
  };

  return (
    <AppForm form={form} onKeyDown={onKeyDown}>
      <AppFlex unit="16" className={styles.container}>
        <AppFormItem name={FILTER_FORM_NAMES.operator} rules={formRules.required}
                     marginless withoutExplain style={{display: "none"}}>
          <AppSelect options={getOperatorOptions(filterType)} placeholder="Условие"
                     allowClear={false} showSearch={false}/>
        </AppFormItem>
        {children}
        <AppFlex direction="row" justifyContent="space-between">
          <AppButton type="primary" size="small"
                     onClick={onSubmit}>
            Поиск
          </AppButton>
          <AppButton type="outline" size="small"
                     onClick={onReset}>
            Сбросить
          </AppButton>
        </AppFlex>
      </AppFlex>
    </AppForm>
  );
};