import {Spin} from "antd";
import {AppBlocker} from "shared/UI/UIKit/Blocker";
import type {AppBlockingLoaderProps} from "./BlockingLoader.types";
import {getSpinnerClassNames} from "./BlockingLoader.utils";

export const AppBlockingLoader = (props: AppBlockingLoaderProps) => {
  const {
    children,
    loading,
    loadingOpacity = "medium",
    className,
    spinnerSize = "default",
    spinnerClassName,
  } = props;
  const spinnerClassNames = getSpinnerClassNames(spinnerClassName);

  const icon = <Spin size={spinnerSize} className={spinnerClassNames}/>;

  return (
    <AppBlocker blocking={loading} opacity={loadingOpacity}
                className={className}
                icon={icon}>
      {children}
    </AppBlocker>
  );
};