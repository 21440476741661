import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppLoader} from "../BaseLoader/Loader";
import styles from "./styles.module.scss";

export const FullPageLoader = () => {
  return (
    <AppFlex alignItems="center" justifyContent="center"
             className={styles.container}>
      <AppLoader size="large"/>
    </AppFlex>
  );
};