import {AppButton} from "shared/UI/UIKit/Button";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {useProfile} from "common/hooks/use-profile";
import {AvatarMenu} from "../../AvatarMenu/AvatarMenu";
import {NavigationItems} from "../NavigationItems/NavigationItems";

export const DesktopHeaderMenu = () => {
  const {profile, login} = useProfile();

  return (
    <AppFlex unit="50" direction="row" alignItems="center">
      {
        profile ?
          <>
            <NavigationItems/>
            <AvatarMenu/>
          </> :
          <AppButton onClick={() => login()} size="large" type="text">
            Войти
          </AppButton>
      }
    </AppFlex>
  );
};