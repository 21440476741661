import {AppFormItem, isAfterDateValidator, isBeforeDateValidator} from "shared/UI/UIKit/Form";
import {AppDatePicker} from "shared/UI/UIKit/DatePicker";
import type {OpUnitType} from "dayjs";
import type {AppFormRuleRender} from "shared/UI/UIKit/Form";
import type {DateIntervalFieldsProps} from "./types";
import {FILTER_FORM_NAMES} from "../../../constants";

const START_ITEM_NAME_PATH = [FILTER_FORM_NAMES.values, 0];
const END_ITEM_NAME_PATH = [FILTER_FORM_NAMES.values, 1];
const TIME_UNIT: OpUnitType = "day";

export const DateIntervalFields = ({
  onChange, focusRef, placeholderStart, placeholderEnd,
}: DateIntervalFieldsProps) => {
  const startItemRule: AppFormRuleRender = ({getFieldValue}) => {
    const validator = isBeforeDateValidator(true, getFieldValue(END_ITEM_NAME_PATH),{
      granularity: TIME_UNIT,
      variant: "before",
      comparisonValueRequired: false,
      message: "Дата начала должна быть меньше даты конца",
    });
    return {validator};
  };

  const endItemRule: AppFormRuleRender = ({getFieldValue}) => {
    const validator = isAfterDateValidator(true, getFieldValue(START_ITEM_NAME_PATH),{
      granularity: TIME_UNIT,
      variant: "after",
      comparisonValueRequired: false,
      message: "Дата конца должна быть больше даты начала",
    });
    return {validator};
  };

  return (
    <>
      <AppFormItem noStyle shouldUpdate>
        {({getFieldValue}) => (
          <AppFormItem name={START_ITEM_NAME_PATH} marginless withoutExplain
                       dependencies={[END_ITEM_NAME_PATH]} rules={[startItemRule]}>
            <AppDatePicker onChange={(value) => onChange([value, getFieldValue(END_ITEM_NAME_PATH)])}
                           placeholder={placeholderStart} ref={focusRef}/>
          </AppFormItem>
        )}
      </AppFormItem>
      <AppFormItem noStyle shouldUpdate>
        {({getFieldValue}) => (
          <AppFormItem name={END_ITEM_NAME_PATH} marginless withoutExplain
                       dependencies={[START_ITEM_NAME_PATH]} rules={[endItemRule]}>
            <AppDatePicker onChange={(value) => onChange([getFieldValue(START_ITEM_NAME_PATH), value])}
                           placeholder={placeholderEnd}/>
          </AppFormItem>
        )}
      </AppFormItem>
    </>
  );
};