import cn from "classnames";
import {AppUserIcon} from "shared/UI/UIKit/icons/User";
import {AppFlex} from "shared/UI/UIKit/Flex";
import styles from "./styles.module.scss";

type UserAvatarProps = {
  size?: "small" | "large";
  className?: string;
}

export const UserAvatar = (props: UserAvatarProps) => {
  const {size = "large", className} = props;
  const iconClassNames = cn(styles[size], className);

  return (
    <AppFlex className={styles.avatar}
             alignItems="center"
             justifyContent={"center"}>
      <AppUserIcon className={iconClassNames}/>
    </AppFlex>
  );
};