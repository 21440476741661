import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {getApothecaryOrderUrl} from "services/navigation";
import {useTableStateManager} from "shared/UI/UIKit/Table";
import type {AppTableOnChange} from "shared/UI/UIKit/Table";
import type {MedicineOrder} from "services/api";
import {selectOrdersState} from "../redux/selector";
import {getOrdersAction} from "../redux/actions";
import {initialTableStateManagerState} from "./get-initial-table-state-manager-state";

export const useApothecaryTable = () => {
  const {orders, loading, total} = useAppSelector(selectOrdersState);
  const dispatch = useAppDispatch();
  const {tableState, tableMethods} = useTableStateManager(initialTableStateManagerState, total);

  const getRowKey = (record: MedicineOrder) => record.id!;

  const onRowClick = (record: MedicineOrder) => {
    return {
      onClick: () => {
        window.open(
          getApothecaryOrderUrl({id: String(record.id)}),
    "_blank",
  "noreferrer",
        );
      },
    };
  };

  const onChange: AppTableOnChange = (state, event) => {
    tableMethods.setTableState(state);
    dispatch(getOrdersAction(event));
  };

  return {
    state: {
      orders,
      loading,
      tableState,
    },
    methods: {
      onRowClick,
      getRowKey,
      onChange
    }
  };
};
