import {AppLink} from "shared/UI/UIKit/Link";
import {getHomeUrl} from "services/navigation";
import styles from "./styles.module.scss";

export const AppLogo = () => {
  return (
    <AppLink to={getHomeUrl()}>
      <img src={"/pharmacy_logo.png"} alt="logo"
           className={styles.logo}/>
    </AppLink>
  );
};