import {Outlet, useLocation} from "react-router-dom";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {PageProtectorBase} from "./Base/PageProtectorBase";

export const AuthorizedPageProtector = () => {
  const location = useLocation();
  const {profile} = useAppSelector(state => state.profileState);

  return (
    <PageProtectorBase to={"/"} condition={!!profile}
                       state={{from: location}}>
      <Outlet/>
    </PageProtectorBase>
  );
};