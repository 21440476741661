import {useAppSelector} from "services/redux/hooks/use-selector";
import type {SelectionSelectFn} from "antd/lib/table/interface";
import type {Medicine} from "services/api";
import type {ChooseMedicineTableProps} from "./types";
import {selectPrescriptionOrderState} from "../../../redux/selectors";
import {MedicineInfo} from "../MedicineInfo/MedicineInfo";

export const useMedicineTable = (props: ChooseMedicineTableProps) => {
  const {loading, medicines} = useAppSelector(selectPrescriptionOrderState);
  const {state, methods} = props.selectionService;

  const onSelectionChange: SelectionSelectFn<Medicine> = (record) => {
    if (methods.has(record.id)) {
      methods.setSelectedKeys([]);
    } else {
      methods.setSelectedKeys([record.id]);
    }
  };

  const getExpandableProps = () => {
    return {
      // eslint-disable-next-line react/no-unstable-nested-components
      expandedRowRender: (record: Medicine) => <MedicineInfo medicine={record}/>,
    };
  };

  const getRowSelection = () => {
    return {
      selectedRowKeys: state.selectedKeys,
      onSelect: onSelectionChange,
      hideSelectAll: true,
    };
  };

  return {
    state: {
      loading,
      medicines
    },
    methods: {
      getRowSelection,
      getExpandableProps
    }
  };

};