import {createSlice} from "@reduxjs/toolkit";
import type {Medicine} from "services/api";
import type {PayloadAction} from "@reduxjs/toolkit";
import type {PrescriptionOrderState, ChangeAmountActionPayload} from "./types";
import {
  getPrescriptionOrderReducerBuilder,
  getMedicineByInnNameReducerBuilder,
  getCreatePrescriptionOrderBuilder
} from "./reducers";

const initialState: PrescriptionOrderState = {
  prescriptionOrder: null,
  loading: false,
  medicines: [],
  selectedPrescriptionOrderItemId: null,
};

const prescriptionOrderSlice = createSlice({
  name: "prescriptionOrder",
  initialState,
  reducers: {
    clearPrescriptionOrder: (state: PrescriptionOrderState) => {
      state.prescriptionOrder = null;
    },
    clearMedicines: (state: PrescriptionOrderState) => {
      state.medicines = [];
    },
    setSelectedPrescriptionOrderItemId: (state: PrescriptionOrderState, action: PayloadAction<number | null>) => {
      state.selectedPrescriptionOrderItemId = action.payload;
    },
    setPrescriptionOrderItemAmount: (state: PrescriptionOrderState, action: PayloadAction<ChangeAmountActionPayload>) => {
      if (!state.prescriptionOrder) {
        return;
      }
      const {index, value} = action.payload;
      const amount = value ? value : 0;
      const pricePerUnit = state.prescriptionOrder.items[index].pricePerUnit;
      state.prescriptionOrder.items[index].amount = amount;
      state.prescriptionOrder.items[index].totalPrice = amount * (pricePerUnit || 0);
    },
    addMedicineToOrderItem: (state: PrescriptionOrderState, action: PayloadAction<number | null>) => {
      let selectedMedicine: Medicine | null;
      if (!action.payload) {
        selectedMedicine = null;
      } else {
        selectedMedicine = state.medicines.find(medicine => medicine.id === action.payload)!;
      }

      state.prescriptionOrder!.items = state.prescriptionOrder!.items.map(item => {
        if (item.id === state.selectedPrescriptionOrderItemId) {
          return {
            ...item,
            medicineDTO: selectedMedicine,
            amount: !selectedMedicine ? null : item.amount || 1,
            pricePerUnit: !selectedMedicine ? null : selectedMedicine.price,
            totalPrice: !selectedMedicine ? null : (item.amount || 1) * selectedMedicine.price
          };
        } else {
          return item;
        }
      });
    }
  },
  extraReducers: (builder) => {
    getPrescriptionOrderReducerBuilder(builder);
    getMedicineByInnNameReducerBuilder(builder);
    getCreatePrescriptionOrderBuilder(builder);
  }
});

export const prescriptionOrderReducer = prescriptionOrderSlice.reducer;
export const {
  clearPrescriptionOrder: clearPrescriptionOrderAction,
  clearMedicines: clearMedicinesAction,
  addMedicineToOrderItem: addMedicineToOrderItemAction,
  setSelectedPrescriptionOrderItemId: setSelectedPrescriptionOrderItemIdAction,
  setPrescriptionOrderItemAmount: setPrescriptionOrderItemAmountAction
} = prescriptionOrderSlice.actions;


