import {AppTable} from "shared/UI/UIKit/Table";
import {columns} from "./columns";
import styles from "./styles.module.scss";
import {useApothecaryTable} from "./use-table";

export const ApothecaryTable = () => {
  const {state, methods} = useApothecaryTable();

  return (
    <div className={styles.wrapper}>
      <AppTable bordered rowClassName={styles.row}
                sorters={state.tableState.sorters}
                filters={state.tableState.filters}
                pagination={state.tableState.pagination}
                columns={columns} loading={state.loading}
                dataSource={state.orders} onChange={methods.onChange}
                rowKey={methods.getRowKey} onRow={methods.onRowClick}/>
    </div>
  );
};