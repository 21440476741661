import type {AppTitleProps} from "shared/UI/UIKit/Title";
import type {CSSProperties, JSX} from "react";

export enum StaticDataPagesItemType {
  TABLE = "TABLE",
  TITLE = "TITLE",
  TEXT = "TEXT",
}
export type StaticDataPagesItem = {
  type: StaticDataPagesItemType;
  content?: string;
  TableComponent?: JSX.Element,
  level?: AppTitleProps["level"],
  align?: CSSProperties["textAlign"],
}