import cn from "classnames";
import type {AppNavLinkProps} from "./NavLink.types";
import styles from "./NavLink.module.scss";

type AppLinkStyleProps = Pick<AppNavLinkProps, "size" | "className"> & {isActive: boolean};

export const getClassNames = (props: AppLinkStyleProps) => {
  return cn({
    [styles.link]: true,
    [styles[`link--${props.size}`]]: props.size,
    [styles.isActive]: props.isActive,
    [props.className as string]: props.className,
  });
};