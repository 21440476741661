import {AppNavLink} from "shared/UI/UIKit/Link";
import {useNavigationItems} from "./use-navigation-items";



type NavigationItemsProps = {
  onClick?: () => void;
}

export const NavigationItems = (props: NavigationItemsProps) => {
  const headerNavItems = useNavigationItems();

  return (
    <>
      {
        headerNavItems.map((item, index) => {
          return (
            <AppNavLink key={index} onClick={props.onClick}
                        to={item.to}>
              {item.children}
            </AppNavLink>
          );
        })
      }
    </>
  );
};