import {combineReducers} from "@reduxjs/toolkit";
import {profileReducer} from "services/profile";
import {ordersReducer} from "features/Apothecary";
import {orderReducer} from "features/ApothecaryOrder";
import {prescriptionOrderReducer} from "features/PrescriptionOrder";

export const reducer = combineReducers({
  ordersState: ordersReducer,
  profileState: profileReducer,
  orderState: orderReducer,
  prescriptionOrderState: prescriptionOrderReducer,
});
