import type {BaseType} from "antd/lib/typography/Base";
import type {MedicineOrderStatus} from "services/api";

export const getStatusColor = (
    status: MedicineOrderStatus
): BaseType => {
  switch (status) {
    case "CANCELLED":
      return "danger";
    default:
      return "success";
  }
};