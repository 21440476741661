import {AppDropdown} from "shared/UI/UIKit/Dropdown";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppText} from "shared/UI/UIKit/Text";
import {getUserShortFullName} from "common/utils/getUserName";
import {AppButton} from "shared/UI/UIKit/Button";
import {useProfile} from "common/hooks/use-profile";
import {UserAvatar} from "../Avatar/Avatar";

export const AvatarMenu = () => {
  const {profile, logout} = useProfile();

  const overlay = (
    <AppFlex unit="6">
      <AppText size={18}>{getUserShortFullName(profile)}</AppText>
      <AppButton onClick={logout} size="large" type="text">Выйти</AppButton>
    </AppFlex>
  );

  return (
    <AppDropdown overlay={overlay} placement="bottom">
      <a onClick={(e) => e.preventDefault()}>
        <UserAvatar size="small"/>
      </a>
    </AppDropdown>
  );
};