import {useEffect} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {FullPageLoader} from "shared/UI/UIKit/Loader";
import {useProfile} from "common/hooks/use-profile";
import {getPrescriptionOrderUrl} from "services/navigation";

 const getLoginUrl = (guid: string) => {
   return `https://login.mos.ru/sps/oauth/ae?client_id=clomsk.ru&prompt=login&response_type=token&response_mode=code&scope=openid+profile+email+phone&state=342a2c0c-d9ef-4cd6-b328-b67d9baf6a7f&nonce=n-asdasdasdc2323&redirect_uri=${location.origin}/prescription-order?numbers=${guid}`;
 };

export const LoadOrderByGuidPage = () => {
  const {profile, login} = useProfile();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const numbers = searchParams.get("numbers");
    if (!numbers) {
      navigate(getPrescriptionOrderUrl({}));
    } else if (profile) {
      navigate(getPrescriptionOrderUrl({numbers: numbers!}));
    } else {
      login(getLoginUrl(numbers!));
    }
  }, [profile]);

  return (
    <FullPageLoader/>
  );
};