import {cloneElement} from "react";
import {AppText} from "shared/UI/UIKit/Text";
import {AppTitle} from "shared/UI/UIKit/Title";
import {AppFlex} from "shared/UI/UIKit/Flex";
import type {StaticDataPagesItem} from "./types";
import {StaticDataPagesItemType} from "./types";
import styles from "./styles.module.scss";

export type StaticContentProps = {
  items: StaticDataPagesItem[];
}

export const StaticContent = (props: StaticContentProps) => {

  const list = props.items.map((item, index) => {
    const {level = 3, content = "", TableComponent = <></>, align = "center"} = item;
    switch (item.type) {
      case StaticDataPagesItemType.TEXT:
        return <AppText key={index} size={16}>{content}</AppText>;
      case StaticDataPagesItemType.TITLE:
        return (
          <AppTitle key={index} style={{textAlign: align}}
                    level={level} className={styles.title}>
            {content}
          </AppTitle>
        );
      case StaticDataPagesItemType.TABLE:
        return cloneElement(TableComponent, {key: index});
    }
  });

  return (
    <AppFlex className={styles.container}>
      {list}
    </AppFlex>
  );
};