import {AppFlex} from "shared/UI/UIKit/Flex";
import {createHTMLMarkup} from "shared/helpers/utils/createHtmlMarkup";
import type {MedicineOrderItem} from "services/api";
import styles from "./styles.module.scss";

type MedicineListItemProps = {
  item: MedicineOrderItem;
}

export const MedicineListItem = ({item}: MedicineListItemProps) => {
  const name = `Название: ${item.medicineDTO?.name || "-"}`;

  return (
    <AppFlex className={styles.card}>
      <div>Номер рецепта: {item.prescriptionNumber}</div>
      <div>Действующее вещество: {item.innName}</div>
      <div dangerouslySetInnerHTML={createHTMLMarkup(name)}/>
      <div>Количество: {item.amount} шт.</div>
      <div>Цена за упаковку: {item.pricePerUnit} р.</div>
    </AppFlex>
  );
};