import dayjs from "dayjs";
import {USER_DATE_FORMAT} from "shared/constants/date";
import {AppText} from "shared/UI/UIKit/Text";
import {getStatusColor} from "common/utils/statusColor";
import {localStatus} from "common/utils/localStatus";
import type {MedicineOrderStatus, MedicineOrder} from "services/api";
import type {AppTableColumns} from "shared/UI/UIKit/Table";

export const columns: AppTableColumns<MedicineOrder> = [
  {
    title: "№",
    dataIndex: ["№"],
    key: "№",
    render: (value: any, record: MedicineOrder, index: number) => {
      return <div>{index + 1}</div>;
    },
    width: "1rem",
  },
  {
    title: "ФИО",
    dataIndex: ["fio"],
    key: "fio",
  },
  {
    key: "birthDate",
    title: "Дата рождения",
    dataIndex: ["birthDate"],
    render: (value: number) => {
      return <div>{value ? dayjs(value).format(USER_DATE_FORMAT) : ""}</div>;
    },
    width: "20rem"
  },
  {
    key: "phone",
    title: "Телефон",
    dataIndex: ["phone"],
  },
  {
    key: "address",
    title: "Адрес",
    dataIndex: ["address"],
  },
  {
    key: "status",
    title: "Статус",
    dataIndex: ["status"],
    render: (value: MedicineOrderStatus) => {
      return (
        <AppText type={getStatusColor(value)} weight={500}>
          {localStatus(value)}
        </AppText>
      );
    }
  }
];