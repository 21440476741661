import type {AppFilterDropdownProps} from "./FilterDropdown.types";
import {DateFilterDropdown} from "../dropdowns/components/DateFilterDropdown/DateFilterDropdown";
import {TimeFilterDropdown} from "../dropdowns/components/TimeFilterDropdown/TimeFilterDropdown";
import {EnumFilterDropdown} from "../dropdowns/components/EnumFilterDropdown/EnumFilterDropdown";
import {TextFilterDropdown} from "../dropdowns/components/TextFilterDropdown/TextFilterDropdown";
import {NumberFilterDropdown} from "../dropdowns/components/NumberFilterDropdown/NumberFilterDropdown";

export const AppFilterDropdown = (props: AppFilterDropdownProps) => {
  switch (props.filter.type) {
    case "string": {
      return (
        <TextFilterDropdown {...props}/>
      );
    }
    case "number": {
      return (
        <NumberFilterDropdown {...props}/>
      );
    }
    case "date": {
      return (
        <DateFilterDropdown {...props}/>
      );
    }
    case "time": {
      return (
        <TimeFilterDropdown {...props}/>
      );
    }
    case "enum": {
      return (
        <EnumFilterDropdown {...props}/>
      );
    }
  }
};