import axios from "axios";
import {ACCESS_TOKEN_NAME, APP_API_URL} from "shared/constants/api";
import type {store} from "services/redux/store";
import type {AxiosInstance} from "axios";

export let appApi: AxiosInstance = axios.create({
  baseURL: APP_API_URL,
});

export const recreateAxiosInstance = (reduxStore: typeof store) => {
  appApi = axios.create({
    baseURL: APP_API_URL,
  });

  appApi.interceptors.response.use( (response) => {
    return response;
  }, function (error) {
    if (error.response.status === 401) {
      reduxStore.dispatch({type: "profile/clearProfile"});
      localStorage.removeItem(ACCESS_TOKEN_NAME);
    }
    return Promise.reject(error);
  });

  appApi.interceptors.request.use((request) => {
    if (request.headers) {
      request.headers["Authorization"] = `Bearer ${getAccessToken()}`;
    }
    return request;
  });
};

const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_NAME);
};


