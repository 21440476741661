import {getApothecaryUrl, getPrescriptionOrderUrl} from "services/navigation";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {isAdminSelector, isProvisorSelector} from "services/profile/redux/selectors";
import type {AppNavLinkProps} from "shared/UI/UIKit/Link";

export const useNavigationItems = () => {
  const isAdmin = useAppSelector(isAdminSelector);
  const isProvisor = useAppSelector(isProvisorSelector);

  const headerNavItems: AppNavLinkProps[] = [
    {
      to: getPrescriptionOrderUrl({}),
      children: "Заказ по рецепту",
    },
  ];

  if (isAdmin || isProvisor) {
    headerNavItems.push({
      to: getApothecaryUrl(),
      children: "Аптекарь",
    });
  }

  return headerNavItems;
};