import {useDidMountEffect} from "shared/helpers/hooks/use-did-mount-effect";
import type {SorterResult} from "antd/lib/table/interface";
import type {AppFilter} from "../../../lib/filters/types";
import type {AppTableItem, AppTablePagination} from "../../types/table.types";
import type {AppTableSorter} from "../../types/sorter.types";
import type {TableChangeServiceOptions, TableChangeService} from "./types";
import {getDefaultTablePagination} from "../../../config/default-table-state";
import {getChangeEvent} from "../../../lib/helpers/change-event/get-change-event";

export const useTableChange = <TRecord extends AppTableItem>({
  onChange,
  sorters,
  filters,
  pagination,
  paginationVisible,
}: TableChangeServiceOptions): TableChangeService<TRecord> => {

  useDidMountEffect(() => {
    const tableState = {
      sorters, filters, pagination,
    };
    const event = getChangeEvent(tableState);
    onChange?.(tableState, event, "init");
  }, []);

  const onSorterChange = (nextSorter: SorterResult<TRecord> | SorterResult<TRecord>[]) => {
    const getSorters = (): AppTableSorter[] => {
      const nextSorters = Array.isArray(nextSorter) ? nextSorter : [nextSorter];

      return sorters.map(resultSorter => {
        const matchedSorter = nextSorters.find(sorter => sorter.columnKey === resultSorter.key);
        return {
          ...resultSorter,
          order: matchedSorter?.order ?? undefined,
        };
      });
    };

    const tableState = {
      sorters: getSorters(),
      filters,
      pagination,
    };
    const event = getChangeEvent(tableState);
    onChange?.(tableState, event, "sort");
  };

  const onPaginationChange = (nextPagination: AppTablePagination) => {
    const tableState = {
      sorters,
      filters,
      pagination: paginationVisible ? nextPagination : pagination,
    };
    const event = getChangeEvent(tableState);
    onChange?.(tableState, event, "paginate");
  };

  const onFilterChange = (nextFilter: AppFilter) => {
    const nextFilters = [...filters];
    const index = nextFilters.findIndex(filterItem => filterItem.key === nextFilter.key);
    if (index !== -1) {
      nextFilters.splice(index, 1, nextFilter);
    } else {
      nextFilters.push(nextFilter);
    }

    const tableState = {
      sorters,
      filters: nextFilters,
      pagination: {...pagination, current: getDefaultTablePagination().current},
    };
    const event = getChangeEvent(tableState);
    onChange?.(tableState, event, "filter");
  };

  const onTableChange: TableChangeService<TRecord>["onTableChange"] = (
    nextPagination, nextFilters, nextSorter, extra
  ) => {
    if (extra?.action === "sort") {
      onSorterChange?.(nextSorter);
    }
    if (extra?.action === "paginate") {
      onPaginationChange?.(nextPagination);
    }
  };

  return {
    onTableChange,
    onFilterChange,
  };
};

