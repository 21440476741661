import {useSetStateManager} from "shared/helpers/hooks/state-managers/set";
import type {Key} from "react";
import type {SelectionService} from "./types";

export const useSelectionService = <T extends Key = Key>(
  initialState: Set<T> = new Set<T>(),
): SelectionService<T> => {
  const {state: {collection}, methods} = useSetStateManager<T>(initialState);
  const selectedKeys = methods.toArray();

  const didSelect = () => {
    return selectedKeys.length !== 0;
  };

  const didSelectSingle = () => {
    return selectedKeys.length === 1;
  };

  const didSelectMultiple = () => {
    return selectedKeys.length > 1;
  };

  const setSelectedKeys = (nextKeys: T[]) => {
    methods.setCollection(new Set(nextKeys));
  };

  const toggle = (key: T) => {
    if (collection.has(key)) {
      methods.deleteItem(key);
    } else {
      methods.addItem(key);
    }
  };

  return {
    state: {
      selectedKeys,
      didSelect: didSelect(),
      didSelectSingle: didSelectSingle(),
      didSelectMultiple: didSelectMultiple(),
    },
    methods: {
      setSelectedKeys,
      add: methods.addItem,
      addKeys: methods.addItems,
      delete: methods.deleteItem,
      deleteKeys: methods.deleteItems,
      toggle,
      has: methods.has,
      clear: methods.clear,
    }
  };
};