import dayjs from "dayjs";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {USER_DATE_FORMAT} from "shared/constants/date";
import {AppText} from "shared/UI/UIKit/Text";
import {getStatusColor} from "common/utils/statusColor";
import {localStatus} from "common/utils/localStatus";
import type {MedicineOrder} from "services/api";
import {DescriptionItem} from "./DescriptionItem/DescriptionItem";

type ApothecaryOrderDescriptionProps = {
  order: MedicineOrder;
}

export const ApothecaryOrderDescription = ({order}: ApothecaryOrderDescriptionProps) => {
  return (
    <AppFlex>
      <DescriptionItem title={"ФИО:"} value={order.fio}/>
      <DescriptionItem title={"Номер телефона:"} value={order.phone}/>
      <DescriptionItem title={"Дата рождения:"} value={order.birthDate ? dayjs(order.birthDate).format(USER_DATE_FORMAT) : "-"}/>
      <DescriptionItem title={"Адрес:"} value={order.address}/>
      <AppFlex direction="row">
        <div>Статус:</div>
        <AppText weight={500} type={getStatusColor(order.status)}>
          {localStatus(order.status)}
        </AppText>
      </AppFlex>
    </AppFlex>
  );
};