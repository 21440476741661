import {MedicineOrderStatus} from "services/api";
import type {ActionReducerMapBuilder} from "@reduxjs/toolkit";
import type {OrderState} from "./types";
import {
  getOrderByIdAction,
  setOrderCanceledAction,
  setOrderInDeliveryAction,
  setOrderReadyAction
} from "./actions";

export const getOrderByIdReducerBuilder = (builder: ActionReducerMapBuilder<OrderState>) => {
  builder.addCase(getOrderByIdAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getOrderByIdAction.fulfilled, (state, action) => {
    state.loading = false;
    state.order = action.payload;
  });
  builder.addCase(getOrderByIdAction.rejected, (state) => {
    state.loading = false;
  });
};

export const setOrderReadyBuilder = (builder: ActionReducerMapBuilder<OrderState>) => {
  builder.addCase(setOrderReadyAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(setOrderReadyAction.fulfilled, (state) => {
    state.loading = false;
    state.order!.status = MedicineOrderStatus.FULFILLED;
  });
  builder.addCase(setOrderReadyAction.rejected, (state) => {
    state.loading = false;
  });
};

export const setOrderInDeliveryBuilder = (builder: ActionReducerMapBuilder<OrderState>) => {
  builder.addCase(setOrderInDeliveryAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(setOrderInDeliveryAction.fulfilled, (state) => {
    state.loading = false;
    state.order!.status = MedicineOrderStatus.HANDED_OVER_TO_DELIVERY;
  });
  builder.addCase(setOrderInDeliveryAction.rejected, (state) => {
    state.loading = false;
  });
};

export const setOrderCanceledBuilder = (builder: ActionReducerMapBuilder<OrderState>) => {
  builder.addCase(setOrderCanceledAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(setOrderCanceledAction.fulfilled, (state) => {
    state.loading = false;
    state.order!.status = MedicineOrderStatus.CANCELLED;
  });
  builder.addCase(setOrderCanceledAction.rejected, (state) => {
    state.loading = false;
  });
};