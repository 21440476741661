import {useProfile} from "common/hooks/use-profile";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppText} from "shared/UI/UIKit/Text";
import {AppButton} from "shared/UI/UIKit/Button";
import {getUserShortFullName} from "common/utils/getUserName";
import {AppSider} from "shared/UI/UIKit/Sider";
import type {MobileNavSiderProps} from "./types";
import {UserAvatar} from "../../../Avatar/Avatar";
import {NavigationItems} from "../../NavigationItems/NavigationItems";

export const MobileNavSider = (props: MobileNavSiderProps) => {
  const {profile, logout} = useProfile();

  const handleOnClick = () => {
    props.hide();
    logout();
  };

  const content = (
    <AppFlex unit="20" alignItems="center">
      <UserAvatar size="large"/>
      <AppText size={18}>{getUserShortFullName(profile)}</AppText>
      <NavigationItems onClick={props.hide}/>
      <AppButton onClick={handleOnClick} size="large" type="text">
        Выйти
      </AppButton>
    </AppFlex>
  );

  return (
    <AppSider hideSider={props.hide}
              isSiderVisible={props.visible}>
      {content}
    </AppSider>
  );
};