import {forwardRef} from "react";
import {AppInput} from "shared/UI/UIKit/Input";
import type {AppInputRef} from "shared/UI/UIKit/Input";
import type {AppMaskedInputProps} from "./MaskedInput.types";
import {useInputMasking} from "./hooks";

export const AppMaskedInput = forwardRef<AppInputRef, AppMaskedInputProps>((props, ref) => {
  const {value, mask, onChange, ...rest} = props;
  const {onInputChange, maskedValue} = useInputMasking(mask, value, onChange);

  return (
    <AppInput {...rest} value={maskedValue} onChange={onInputChange} ref={ref}/>
  );
});
