import {useEffect} from "react";
import type {TableRowSelection} from "antd/lib/table/interface";
import type {AppTableItem} from "../../types/table.types";
import type {TableRowSelectionServiceOptions} from "./types";
import {isGroupingByDataIndexes} from "../../Table.utils";

export const useTableRowSelection = <TRecord extends AppTableItem>({
  rowSelection,
  initialDataSource,
  groupingConfig,
}: TableRowSelectionServiceOptions<TRecord>): TableRowSelection<TRecord> | undefined => {
  useEffect(() => {
    const {resetSelectedRowKeysOnDataSourceChange = true, onChange} = rowSelection ?? {};

    if (resetSelectedRowKeysOnDataSourceChange) {
      onChange?.([], [], {type: "none", initiator: "table"});
    }
  }, [initialDataSource]);

  const getRowSelection = (): TableRowSelection<TRecord> | undefined => {
    if (!rowSelection) {
      return rowSelection;
    }

    return {
      checkStrictly: !(groupingConfig && isGroupingByDataIndexes(groupingConfig)),
      ...rowSelection,
      onChange: (selectedRowKeys, selectedRows, {type}) => {
        let nextSelectedRows = selectedRows;
        let nextSelectedRowKeys = selectedRowKeys;

        if (groupingConfig && isGroupingByDataIndexes(groupingConfig)) {
          nextSelectedRows = nextSelectedRows.filter(row => !row._group);
          nextSelectedRowKeys = nextSelectedRows.map(row => row.key!);
        }

        rowSelection.onChange?.(nextSelectedRowKeys, nextSelectedRows, {type, initiator: "user"});
      },
    };
  };

  return getRowSelection();
};