import {Form} from "antd";
import type {PropsWithChildren} from "react";
import {EditableContext} from "../EditableContext/EditableContext";

export const EditableWrapper = ({children}: PropsWithChildren) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        {children}
      </EditableContext.Provider>
    </Form>
  );
};