import cn from "classnames";
import {AppFlex} from "shared/UI/UIKit/Flex";
import type {Medicine} from "services/api";
import {MedicineInfoItem} from "./MedicineInfoItem/MedicineInfoItem";
import styles from "./styles.module.scss";

type MedicineInfoProps = {
  medicine: Medicine;
}

export const MedicineInfo = (props: MedicineInfoProps) => {
  const {information, instructions, manufacturer, storageTime, storageConditions} = props.medicine;

  return (
    <AppFlex unit="30" className={cn(styles.container, "expandable-medicine-info")}>
      <MedicineInfoItem title="Информация" value={information}/>
      <MedicineInfoItem title="Инструкция" value={instructions}/>
      <MedicineInfoItem title="Производитель" value={manufacturer}/>
      <MedicineInfoItem title="Срок хранения" value={storageTime}/>
      <MedicineInfoItem title="Условия хранения" value={storageConditions}/>
    </AppFlex>
  );
};