export const errorMessages = {
  required: "Обязательное поле",
  requiredBlock: "Обязательные поля",
  requiredArray: "Выберите минимум одно значение",
  requiredNumber: "Поле может содержать только цифры",
  requiredDate: "Поле может содержать только дату",
  requiredTime: "Поле может содержать только время",
  phoneError: "Неверный формат номера",
  emailError: "Неверный формат e-mail",
  numberError: "Неверное число",
  dateError: "Неверная дата",
} as const;
