import {groupBy} from "shared/helpers/utils/grouped-by";
import type {AppTableItem} from "../../../types/table.types";
import type {AppTableGroupingByParentKey} from "../../../types/group.types";

export const groupByParentKey = <TRecord extends AppTableItem>(
  dataSource: TRecord[],
  {parentKey, calcChildrenNumber = true}: AppTableGroupingByParentKey<TRecord>,
): TRecord[] => {
  const callback = calcChildrenNumber ? onParentItemChange : undefined;

  return groupBy(dataSource, "key", parentKey, true, callback);
};

const onParentItemChange = <TRecord extends AppTableItem>(parentItem: TRecord) => {
  parentItem._childrenNumber = (parentItem?._childrenNumber ?? 0) + 1;
};