import {notification} from "antd";
import type {AxiosError} from "axios";

export const appErrorHandler = (error: AxiosError, message = "Непредвиденная ошибка") => {
  const errorMessage = typeof error.response?.data === "string" ? error.response!.data : message;

  if (error.response?.status !== 401) {
    notification.error({message: errorMessage});
  }

  throw error;
};