import {AppFlex} from "shared/UI/UIKit/Flex";
import {isMobile} from "shared/helpers/utils/is-mobile";
import {AppLogo} from "./Logo/Logo";
import {DesktopHeaderMenu} from "./Menu/desktop/DesktopMenu";
import styles from "./Header.module.scss";
import {MobileHeaderMenu} from "./Menu/mobile/MobileMenu";

export const AppHeader = () => {
  return (
    <header className={styles.header}>
      <AppFlex className={styles.wrapper}
               direction={"row"} alignItems={"center"}
               justifyContent="space-between">
        <AppLogo/>
        <nav className={styles.nav}>
          {isMobile() ? <MobileHeaderMenu/> : <DesktopHeaderMenu/>}
        </nav>
      </AppFlex>
    </header>
  );
};
