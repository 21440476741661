import {AppButton} from "shared/UI/UIKit/Button";
import type {MedicineOrder} from "services/api";
import styles from "./styles.module.scss";

type CreatePrescriptionOrderButtonProps = {
  prescriptionOrder: MedicineOrder | null;
  openCreateOrderModal: () => void;
}

export const CreatePrescriptionOrderButton = (props: CreatePrescriptionOrderButtonProps) => {
  const {prescriptionOrder, openCreateOrderModal} = props;

  if (!prescriptionOrder) {
    return null;
  }

  const isDisabled = () => {
    return !prescriptionOrder.items?.some(item => item.amount);
  };

  return (
    <AppButton disabled={isDisabled()}
               className={styles.createButton}
               onClick={openCreateOrderModal}>
      Создать заказ
    </AppButton>
  );
};