import type {AppFilter} from "../../filters/types";
import type {AppTablePagination} from "../../../ui/types/table.types";
import type {AppTableSorter} from "../../../ui/types/sorter.types";
import type {AppTableChangeEvent} from "../../../ui/types/event.types";
import {getSort} from "./get-sort";
import {getFilter} from "./get-filter";
import {getLimitPage} from "./get-limit-offset";

type TableState = {
  sorters: AppTableSorter[],
  filters: AppFilter[],
  pagination: AppTablePagination,
}

export const getChangeEvent = (tableState: TableState): AppTableChangeEvent => {
  return {
    sort: getSort(tableState.sorters),
    ...getFilter(tableState.filters),
    ...getLimitPage(tableState.pagination),
  };
};