import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppTitle} from "shared/UI/UIKit/Title";
import {AppText} from "shared/UI/UIKit/Text";
import type {MedicineOrder, MedicineOrderItem} from "services/api";
import type {FormInstance} from "antd";
import {OrderInfo} from "../OrderInfo/OrderInfo";
import {AddressFrom} from "../AddressForm/AddressFrom";

type ModalContentProps = {
  filteredItems: MedicineOrderItem[];
  form: FormInstance;
  order: MedicineOrder | null;
}

export const ModalContent = (props: ModalContentProps) => {
  console.log(props.order);
  return props.order?.emiasId ? (
    <AppFlex>
      <AppFlex direction="row" alignItems="flex-end">
        <AppTitle level={3}>ID Заказа: </AppTitle>
        <AppText size={18}>{props.order.id}</AppText>
      </AppFlex>
      <AppFlex direction="row" alignItems="flex-end">
        <AppTitle level={3}>ЕМИАС ID: </AppTitle>
        <AppText size={18}>{props.order.emiasId}</AppText>
      </AppFlex>
      <OrderInfo orderItemsWithAmount={props.filteredItems}/>
      <AppFlex unit="8">
        <AppTitle level={3}>Способ обслуживания: </AppTitle>
        <AppText size={18}>
          {
            props.order.delivery ?
              "Доставка заказа (при возможности доставки) осуществляется курьером ежедневно с 09:00 до 20:00 в пределах административных округов города Москвы (за исключением ТиНАО) в течение одного дня с даты формирования заказа" :
              "Самовывоз осуществляется из Аптеки #5, находящейся по адресу Москва, ул. Петровка 19, стр. 1"
          }
        </AppText>
      </AppFlex>
      <AppFlex unit="8">
        <AppTitle level={3}>Способ получения QR-кода заказа: </AppTitle>
        <AppText size={18}>
          В мобильном приложении ЕМИАС.ИНФО или личном кабинете пациента на портале <a href="mos.ru" target="_blank">mos.ru</a>
        </AppText>
      </AppFlex>
      <AppText size={18} italic>
        Отмену заказа можно осуществить по телефонам тел: +7 (495) 628-48-96; +7 (495) 623-73-79
      </AppText>
    </AppFlex>
  ) : (
    <AppFlex>
      <OrderInfo orderItemsWithAmount={props.filteredItems}/>
      <AddressFrom form={props.form}/>
    </AppFlex>
  );
};
