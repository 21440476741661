import type {ActionReducerMapBuilder} from "@reduxjs/toolkit";
import type {OrdersState} from "./types";
import {getOrdersAction} from "./actions";

export const getOrdersReducerBuilder = (builder: ActionReducerMapBuilder<OrdersState>) => {
  builder.addCase(getOrdersAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getOrdersAction.fulfilled, (state, action) => {
    state.loading = false;
    state.orders = action.payload.data;
    state.total = action.payload.total;
  });
  builder.addCase(getOrdersAction.rejected, (state) => {
    state.loading = false;
  });
};