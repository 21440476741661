import {getProfile} from "services/api";
import {createAppAsyncThunk} from "services/redux/types";
import type {AxiosError} from "axios";

export const getProfileAction = createAppAsyncThunk("profile/get", async() => {
  try {
    return await getProfile();
  } catch (e: unknown) {
    throw e as AxiosError;
  }
});