import type {AppTableSingleColumn} from "../../../types/column.types";
import type {AppTableItem} from "../../../types/table.types";
import {ColumnCell} from "./components/ColumnCell/ColumnCell";

export const addRenderToColumn = <TRecord extends AppTableItem>(
  column: AppTableSingleColumn<TRecord>
): AppTableSingleColumn<TRecord> => {
  return {
    ...column,
    render: (value: any, record: TRecord, index: number) => {
      return (
        <ColumnCell value={value} record={record} index={index}
                    column={column}/>
      );
    }
  };
};