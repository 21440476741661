import type {UserProfile} from "services/api";

export const getUserShortFullName = (user?: UserProfile | null) => {
  if (!user) {
    return "";
  }
  const {firstName, middleName, lastName} = getFullNameParts(user);

  const nameFirstLetter = firstName[0] ? firstName[0].toUpperCase() : "";
  const nameAbbr = nameFirstLetter ? ` ${nameFirstLetter}.` : "";

  const middleNameFirstLetter = middleName[0] ? middleName[0].toUpperCase() : "";
  const middleNameAbbr = middleNameFirstLetter ? ` ${middleNameFirstLetter}.` : "";

  return `${lastName}${nameAbbr}${middleNameAbbr}`;
};

export const getFullNameParts = (user: UserProfile) => {
  return {
    firstName: user && user.firstName ? user.firstName : "",
    middleName: user && user.middleName ? user.middleName : "",
    lastName: user && user.lastName ? user.lastName : "",
  };
};