import {forwardRef} from "react";
import {DatePicker} from "antd";
import {AppCloseIcon} from "shared/UI/UIKit/icons/Close";
import type {AppDatePickerComponent, AppPickerBaseProps} from "./DatePickerBase.types";
import styles from "../../styles.module.scss";

export const AppDatePickerBase = forwardRef<AppDatePickerComponent, AppPickerBaseProps>(
  (props, ref) => {
    return <DatePicker {...props} ref={ref} clearIcon={<AppCloseIcon className={styles.clear}/>}/>;
  });
