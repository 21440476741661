import type {AppTableSorter} from "../../../types/sorter.types";
import type {AppTableItem} from "../../../types/table.types";
import type {AppTableSingleColumn} from "../../../types/column.types";
import type {SortableColumn} from "../types";
import {DEFAULT_TABLE_SORTER_WEIGHT} from "../../../../config/default-table-state";

export const addSorterToColumn = <TRecord extends AppTableItem>(
  column: AppTableSingleColumn<TRecord>,
  sorters: AppTableSorter[],
  multipleSorter: boolean,
): SortableColumn<TRecord> => {
  const matchedSorter = sorters.find(sorterItem => sorterItem.key === column.key);

  if (!matchedSorter) {
    return column;
  }

  return {
    ...column,
    sorter: multipleSorter
      ? {multiple: matchedSorter.weight ?? DEFAULT_TABLE_SORTER_WEIGHT}
      : true,
    sortOrder: matchedSorter.order,
  };
};