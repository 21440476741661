import {useAppSelector} from "services/redux/hooks/use-selector";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {clearProfileAction, selectProfile} from "services/profile";
import {ACCESS_TOKEN_NAME} from "shared/constants/api";
import {loginUrl, logoutUrl} from "./constants";

export const useProfile = () => {
  const {profile} = useAppSelector(selectProfile);
  const dispatch = useAppDispatch();

  const login = (customLoginUrl?: string) => {
    location.href = customLoginUrl || loginUrl;
  };

  const logout = () => {
    dispatch(clearProfileAction());
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    location.href = logoutUrl;
  };

  return {
    profile,
    login,
    logout
  };
};