import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import type {ApothecaryOrderPageParams} from "services/navigation";
import {selectOrderState} from "../../redux/selector";
import {
  getOrderByIdAction,
  setOrderCanceledAction,
  setOrderInDeliveryAction,
  setOrderReadyAction
} from "../../redux/actions";

export const useApothecaryOrder = () => {
  const {id} = useParams<ApothecaryOrderPageParams>();
  const {order, loading} = useAppSelector(selectOrderState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getOrderByIdAction(id!));
  }, []);

  const ready = () => {
    dispatch(setOrderReadyAction());
  };

  const canceled = () => {
    dispatch(setOrderCanceledAction());
  };

  const inDelivery = () => {
    dispatch(setOrderInDeliveryAction());
  };

  return {
    loading,
    order,
    ready,
    canceled,
    inDelivery,
  };
};