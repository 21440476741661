import {useState} from "react";
import type {SetStateManager} from "./types";

export const useSetStateManager = <T,>(
  initialState: Set<T> = new Set<T>(),
  cloneInitialState = true,
): SetStateManager<T> => {
  const [collection, setCollection] = useState<Set<T>>(
    () => cloneInitialState ? new Set(initialState) : initialState
  );

  const _collectionToArray = (_set: Set<T>) => {
    return Array.from(_set);
  };

  const addItem = (item: T) => {
    setCollection(currentState => {
      const cloneSet = new Set(currentState);
      cloneSet.add(item);
      return cloneSet;
    });
  };

  const addItems = (items: T[]) => {
    setCollection(currentState => {
      const cloneArray = _collectionToArray(currentState);
      cloneArray.push(...items);
      return new Set(cloneArray);
    });
  };

  const deleteItem = (item: T) => {
    setCollection(currentState => {
      const cloneSet = new Set(currentState);
      cloneSet.delete(item);
      return cloneSet;
    });
  };

  const deleteItems = (items: T[]) => {
    setCollection(currentState => {
      const cloneArray = _collectionToArray(currentState);
      const filtered = cloneArray.filter(arrayItem => !items.some(item => item === arrayItem));
      return new Set(filtered);
    });
  };

  const has = (item: T) => {
    return collection.has(item);
  };

  const toArray = () => {
    return _collectionToArray(collection);
  };

  const setCollectionItems = (items: Set<T>, clone = true) => {
    setCollection(clone ? new Set(items) : items);
  };

  const clear = () => {
    setCollection(new Set());
  };

  return {
    state: {
      collection,
    },
    methods: {
      toArray,
      setCollection: setCollectionItems,
      addItem,
      addItems,
      deleteItem,
      deleteItems,
      has,
      clear,
    }
  };
};