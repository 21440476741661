import type {ActionReducerMapBuilder} from "@reduxjs/toolkit";
import type {ProfileState} from "./types";
import {getProfileAction} from "./actions";

export const getProfileReducerBuilder = (builder: ActionReducerMapBuilder<ProfileState>) => {
  builder.addCase(getProfileAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getProfileAction.fulfilled, (state, action) => {
    state.loading = false;
    state.profile = action.payload;
  });
  builder.addCase(getProfileAction.rejected, (state) => {
    state.loading = false;
  });
};