import {notification} from "antd";
import {createAppAsyncThunk} from "services/redux/types";
import {getMedicines, loadOrder, loadOrderByGuid, sendOrder} from "services/api";
import type {AxiosError} from "axios";
import type {LoadOrderRequestBody, MedicineOrder} from "services/api";
import type {AddressFormValues} from "../components/CreateOrderModal/AddressForm/types";
import {manageMedicineOrderIds} from "./utils";

export const loadPrescriptionOrderAction = createAppAsyncThunk("prescriptionOrder/load", async(body: LoadOrderRequestBody) => {
  try {
    const res = await loadOrder(body);
    return manageMedicineOrderIds(res, "add");
  } catch (e: unknown) {
    throw e as AxiosError;
  }
});

export const loadPrescriptionOrderByGuidAction = createAppAsyncThunk("prescriptionOrder/loadByGuid", async(guid: string) => {
  try {
    const res = await loadOrderByGuid(guid);
    return manageMedicineOrderIds(res, "add");
  } catch (e: unknown) {
    throw e as AxiosError;
  }
});

export const createPrescriptionOrderAction = createAppAsyncThunk("prescriptionOrder/create", async(values: AddressFormValues, thunkApi) => {
  const {prescriptionOrder} = thunkApi.getState().prescriptionOrderState;

  const body: MedicineOrder = {
    ...prescriptionOrder!,
    ...values,
    address: values.address ? values.address : "",
  };
  try {
    const res = await sendOrder(manageMedicineOrderIds(body, "remove"));
    notification.success({message: "Заказ успешно оформлен"});
    return {
      ...res,
      items: [...prescriptionOrder!.items],
    };
  } catch (e: unknown) {
    throw e as AxiosError;
  }
});

export const loadMedicineByInnNameAction = createAppAsyncThunk("prescriptionOrder/loadMedicine", async(innName: string) => {
  try {
    return await getMedicines(innName);
  } catch (e: unknown) {
    throw e as AxiosError;
  }
});