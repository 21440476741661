import {useNavigate} from "react-router-dom";
import {AppText} from "shared/UI/UIKit/Text";
import {AppButton} from "shared/UI/UIKit/Button";
import {getApothecaryUrl} from "services/navigation";
import styles from "./styles.module.scss";

export const BackButton = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(getApothecaryUrl());
  };
  const backIcon = <AppText className={styles.back}>←</AppText>;

  return (
    <AppButton onClick={goBack}
               icon={backIcon}>
      Вернуться к списку заказов
    </AppButton>
  );
};