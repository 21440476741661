import type {AppFilter, AppFilterSingleCondition} from "../../filters/types";
import {getFilterConditions} from "../../filters/utils/get-filter-conditions";

export const getFilter = (filters: AppFilter[]): Record<string, string> => {
  const filterConditions = getFilterConditions(filters);
  if (!filterConditions) {
    return {};
  }
  return filterConditions.reduce((acc, item) => {
    const key = (item as AppFilterSingleCondition)!.property;
    acc[key] = (item as AppFilterSingleCondition)!.value!;
    return acc;
  }, {} as Record<string, string>);
};