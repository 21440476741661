import React from "react";
import {BrowserRouter} from "react-router-dom";
import {ConfigProvider} from "antd";
import ruRU from "antd/es/locale/ru_RU";
import type {AppProvidersProps} from "./Providers.types";

export const AppProviders = ({children}: AppProvidersProps) => {
  return (
    <ConfigProvider locale={ruRU}>
      <BrowserRouter>
        {children}
      </BrowserRouter>
    </ConfigProvider>
  );
};