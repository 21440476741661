import {Dropdown} from "antd";
import type {AppDropdownProps} from "./Dropdown.types";
import {getClassNames} from "./Dropdown.utils";
import styles from "./Dropdown.module.scss";

export const AppDropdown = (props: AppDropdownProps) => {
  const {trigger = ["click"], className, overlay, ...rest} = props;
  const classNames = getClassNames(className);

  const dropDownRender = () => {
    return (
      <div className={styles.overlay}>
        {overlay}
      </div>
    );
  };

  return (
    <Dropdown {...rest} className={classNames}
              trigger={trigger}
              dropdownRender={dropDownRender}>
      {props.children}
    </Dropdown>
  );
};