import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppText} from "shared/UI/UIKit/Text";
import type {AppTableItem} from "../../../../../types/table.types";
import type {ColumnCellProps} from "./ColumnCell.types";
import {EmailRenderer} from "../../../../../../lib/helpers/renderers/components/EmailRenderer";
import {LinkRenderer} from "../../../../../../lib/helpers/renderers/components/LinkRenderer";
import {TextRenderer} from "../../../../../../lib/helpers/renderers/components/TextRenderer";
import {TooltipRenderer} from "../../../../../../lib/helpers/renderers/components/TooltipRenderer";
import {DateRenderer} from "../../../../../../lib/helpers/renderers/components/DateRenderer";
import {TimeRenderer} from "../../../../../../lib/helpers/renderers/components/TimeRenderer";
import {DateTimeRenderer} from "../../../../../../lib/helpers/renderers/components/DateTimeRenderer";
import styles from "./ColumnCell.module.scss";

const DEFAULT_MAX_TOOLTIP_CONTENT_WIDTH = "20rem";

export const ColumnCell = <TRecord extends AppTableItem>({
  value, record, index, column,
}: ColumnCellProps<TRecord>) => {
  const renderValue = () => {
    const val = value === undefined || value === null ? value : String(value);

    switch (column.type) {
      case "date": return (
        <DateRenderer date={val}/>
      );
      case "time": return (
        <TimeRenderer time={val}/>
      );
      case "datetime": return (
        <DateTimeRenderer date={val}/>
      );
      case "link": return (
        <LinkRenderer href={val}/>
      );
      case "email": return (
        <EmailRenderer email={val}/>
      );
      default: return (
        <TextRenderer text={val}/>
      );
    }
  };

  const renderContent = () => {
    if (column.render) {
      return (
        <>{column.render(value, record, index)}</>
      );
    }

    const content = renderValue();

    return column.ellipsis ? (
      <TooltipRenderer content={content}/>
    ) : content;
  };

  const renderChildrenNumber = () => {
    if (value === undefined || value === null
      || !record._childrenNumber
      || column.showChildrenNumber === false
    ) {
      return null;
    }

    return (
      <AppText className={styles["children-number"]}>
        {`(${record._childrenNumber})`}
      </AppText>
    );
  };

  return (
    <AppFlex display="inline-flex" direction="row" unit="4"
             style={{maxWidth: column.width ?? DEFAULT_MAX_TOOLTIP_CONTENT_WIDTH}}>
      {renderContent()}
      {renderChildrenNumber()}
    </AppFlex>
  );
};