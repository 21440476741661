import {useNavigate} from "react-router-dom";
import {AppPage} from "common/components/Layout";
import {AppButton} from "shared/UI/UIKit/Button";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppTitle} from "shared/UI/UIKit/Title";
import {getHomeUrl} from "services/navigation";
import styles from "./styles.module.scss";

export const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <AppPage documentTitle="Страница не найдена">
      <AppFlex className={styles.container}
               direction="column"
               alignItems="center"
               justifyContent="center">
        <AppTitle>Ошибка 404. Страница не найдена</AppTitle>
        <AppButton onClick={() => navigate(getHomeUrl(), {replace: true})}>
          Вернуться на главную
        </AppButton>
      </AppFlex>
    </AppPage>
  );
};