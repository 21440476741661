import {getOrders} from "services/api";
import {createAppAsyncThunk} from "services/redux/types";
import type {AxiosError} from "axios";
import type {AppTableChangeEvent} from "shared/UI/UIKit/Table";

export const getOrdersAction = createAppAsyncThunk("orders/get", async(event: AppTableChangeEvent) => {
  try {
    return await getOrders(event);
  } catch (e: unknown) {
    throw e as AxiosError;
  }
});