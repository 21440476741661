import {AppFlex} from "shared/UI/UIKit/Flex";

type DescriptionItemProps = {
  title: string;
  value: string;
}

export const DescriptionItem = ({title, value}: DescriptionItemProps) => {
  return (
    <AppFlex direction="row">
      <div>{title}</div>
      <div>{value}</div>
    </AppFlex>
  );
};