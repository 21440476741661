import {Routes, Route} from "react-router-dom";
import {AppLayout} from "common/components/Layout";
import {FullPageLoader} from "shared/UI/UIKit/Loader";
import {AuthorizedPageProtector, ProvisorPageProtector} from "common/components/Protectors";
import {useInitProfile} from "services/profile";
import {notFoundPageRoute} from "./404/config";
import {apothecaryPageRoute} from "./apothecary/config";
import {apothecaryOrderPageRoute} from "./apothecary-order/config";
import {homePageRoute} from "./home/config";
import {prescriptionOrderPageRoute} from "./prescription-order/config";
import {aboutPageRoute} from "./about/config";
import {termsOfUsePageRoute} from "./terms-of-use/config";
import {termsOfSalePageRoute} from "./terms-of-sale/config";
import {privacyPolicyPageRoute} from "./privacy-policy/config";
import {loadOrderByGuidPageRoute} from "./load-order-by-guid/config";

export const AppRouting = () => {
  const {loading} = useInitProfile();

  if (loading) {
    return <FullPageLoader/>;
  }

  return (
    <Routes>
      <Route element={<AppLayout/>}>
        <Route path={homePageRoute.path} element={homePageRoute.element}/>
        <Route element={<AuthorizedPageProtector/>}>
          <Route path={prescriptionOrderPageRoute.path} element={prescriptionOrderPageRoute.element}/>
          <Route element={<ProvisorPageProtector/>}>
            <Route path={apothecaryOrderPageRoute.path} element={apothecaryOrderPageRoute.element}/>
            <Route path={apothecaryPageRoute.path} element={apothecaryPageRoute.element}/>
          </Route>
        </Route>
        <Route path={aboutPageRoute.path} element={aboutPageRoute.element}/>
        <Route path={termsOfUsePageRoute.path} element={termsOfUsePageRoute.element}/>
        <Route path={termsOfSalePageRoute.path} element={termsOfSalePageRoute.element}/>
        <Route path={privacyPolicyPageRoute.path} element={privacyPolicyPageRoute.element}/>
        <Route path={notFoundPageRoute.path} element={notFoundPageRoute.element}/>
        <Route path={loadOrderByGuidPageRoute.path} element={loadOrderByGuidPageRoute.element}/>
      </Route>
    </Routes>
  );
};
