import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppInputNumber} from "shared/UI/UIKit/InputNumber";
import {AppButton} from "shared/UI/UIKit/Button";
import {AppFormItem} from "shared/UI/UIKit/Form";
import type {MedicineOrderItem} from "services/api";
import styles from "./styles.module.scss";

type PrescriptionOrderItemCardProps = {
  orderItem: MedicineOrderItem;
  chooseMedicine: (id: number) => void;
  changeAmount: (value: number | null) => void;
}

export const PrescriptionOrderItemCard = (props: PrescriptionOrderItemCardProps) => {
  const {orderItem, chooseMedicine, changeAmount} = props;

  const getButtonText = () => {
    return orderItem.medicineDTO ? {
      __html: `${orderItem.medicineDTO.name} ${orderItem.medicineDTO.zipInfo}`
    } : {
      __html: "Выбрать препарат"
    };
  };

  return (
    <AppFlex className={styles.item}>
      <div>{orderItem.innName}</div>
      <div>{orderItem.esklp}</div>
      <AppButton size="large" onClick={() => chooseMedicine(orderItem.id!)}>
        <span dangerouslySetInnerHTML={getButtonText()}/>
      </AppButton>
      <AppFlex direction="row" alignItems="center"
               justifyContent="center">
        <div>Количество</div>
        <AppFormItem size="xs" marginless>
          <AppInputNumber suffix={"уп."}
                          value={orderItem.amount}
                          disabled={!orderItem.medicineDTO}
                          onChange={changeAmount} controls/>
        </AppFormItem>
      </AppFlex>
    </AppFlex>
  );
};