export type UserProfile = {
  phone: string;
  guid: string;
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  role: UserProfileRole;
}

export enum UserProfileRole {
  ROLE_ADMIN = "ROLE_ADMIN",
  ROLE_PROVISOR = "ROLE_PROVISOR",
  ROLE_USER = "ROLE_USER",
}