import type {MedicineOrder} from "services/api";

export const manageMedicineOrderIds = (
    order: MedicineOrder,
    action: "remove" | "add"
): MedicineOrder => {
  return {
    ...order,
    id: action === "add" ? Math.random() : null,
    items: order.items.map(item => {
      return {
        ...item,
        id: action === "add" ? Math.random() : null,
      };
    })
  };
};