import {useEffect} from "react";
import {useAppForm, useAppFormWatch} from "shared/UI/UIKit/Form";
import {useAutoFocusEffect} from "shared/helpers/hooks/use-auto-focus-effect";
import type {Focusable} from "shared/types";
import type {AppFilterOperator} from "../../../constants";
import type {FilterFormValues} from "../../types";
import type {FilterDropdownServiceOptions, FilterDropdownService} from "./types";
import {FILTER_FORM_NAMES} from "../../constants";

export const useFilterDropdown = <TValue, TRefElement extends Focusable = HTMLInputElement>({
  filter,
  onChange,
  close,
  visible,
  getInitialFormValues,
  getFilterToSubmit,
  getFilterToReset,
}: FilterDropdownServiceOptions<TValue>): FilterDropdownService<TValue, TRefElement> => {
  const [form] = useAppForm<FilterFormValues<TValue>>();
  const operator = useAppFormWatch<AppFilterOperator>(FILTER_FORM_NAMES.operator, form);
  const focusRef = useAutoFocusEffect<TRefElement>([visible, operator], visible);

  useEffect(() => {
    if (visible) {
      const initialFormValues = getInitialFormValues(filter);
      form.setFieldsValue(initialFormValues as any); //todo remove any, fix types
    }
  }, [visible]);

  const onSubmit = () => {
    form.validateFields()
      .then(formValues => {
        const nextFilter = getFilterToSubmit(filter, formValues);
        onChange(nextFilter);
        close();
      })
      .catch(e => e);
  };

  const onReset = () => {
    const nextFilter = getFilterToReset(filter);
    onChange(nextFilter);
    close();
  };

  return {
    form,
    focusRef,
    onSubmit,
    onReset,
  };
};