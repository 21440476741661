import {useEffect, useState} from "react";
import {AppForm, AppFormItem, formRules} from "shared/UI/UIKit/Form";
import {AppInput} from "shared/UI/UIKit/Input";
import {AppMaskedInput, PHONE_MASK} from "shared/UI/UIKit/MaskedInput";
import {AppCheckbox} from "shared/UI/UIKit/Checkbox";
import {AppText} from "shared/UI/UIKit/Text";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppTitle} from "shared/UI/UIKit/Title";
import type {FormInstance} from "antd";
import type {AddressFormValues} from "./types";
import styles from "./styles.module.scss";

type AddressFromProps = {
  form: FormInstance<AddressFormValues>;
}

export const AddressFrom = (props: AddressFromProps) => {
  const [isDelivery, setIsDelivery] = useState(true);

  useEffect(() => {
    if (!isDelivery) {
      props.form.resetFields(["address"]);
    }
  }, [isDelivery]);

  useEffect(() => {
    props.form.setFieldValue("delivery", true);
    setIsDelivery(true);
  }, []);

  return (
    <AppForm form={props.form}>
      <AppFormItem marginless={!isDelivery} valuePropName="checked" name="delivery">
        <AppCheckbox onChange={() => setIsDelivery(!isDelivery)}>
          <AppText size={18}>Доставка</AppText>
        </AppCheckbox>
      </AppFormItem>
      {
        isDelivery ? (
          <AppFormItem name="address" rules={formRules.required}>
            <AppInput placeholder="Адрес доставки"/>
          </AppFormItem>
        ) : (
          <AppFlex className={styles.address} unit="8" direction="row" alignItems="center">
            <AppTitle level={4}>
              Адрес аптеки:
            </AppTitle>
            <AppText size={18}>
              Ул. Петровка 19 стр.1
            </AppText>
          </AppFlex>
        )
      }
      <AppFormItem name="phone" rules={formRules.requiredPhoneNumber}>
        <AppMaskedInput mask={PHONE_MASK} placeholder="Телефон"/>
      </AppFormItem>
    </AppForm>
  );
};