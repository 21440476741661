import type {AppTableItem} from "../../../../types/table.types";
import type {AppTableGroupingByDataIndexes} from "../../../../types/group.types";
import {getGroupedDataSource} from "./get-grouped-data-source";

export const groupByDataIndexes = <TRecord extends AppTableItem>(
  dataSource: TRecord[],
  {dataIndexes, calcChildrenNumber = true}: AppTableGroupingByDataIndexes,
): TRecord[] => {
  return dataIndexes.reduce((resultDataSource, dataIndex, index) => {
    if (index === 0) {
      return getGroupedDataSource(resultDataSource, dataIndex, "group", calcChildrenNumber);
    }

    return resultDataSource.map(item => getItemChildrenGrouping(item, index, dataIndex, calcChildrenNumber));
  }, dataSource);
};

const getItemChildrenGrouping = <TRecord extends AppTableItem>(
  item: TRecord,
  nestingLevel: number,
  dataIndex: string[],
  calcChildrenNumber: boolean,
): TRecord => {
  if (!item.children) {
    return item;
  }

  item.children = nestingLevel === 1
    ? getGroupedDataSource(item.children, dataIndex, `${item.key}`, calcChildrenNumber)
    : item.children.map(child => getItemChildrenGrouping(child, nestingLevel - 1, dataIndex, calcChildrenNumber));

  return item;
};