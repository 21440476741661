import {errorMessages} from "../../error-messages";

const condition = new RegExp(/^[+]?[(]?[\d]{3}[)]?[-\s.]?[\d]{3}[-\s.]?[\d]{4,6}$/im);

export const phoneValidator = (required = true) => {
  return (_: any, value: string) => {
    if (!value) {
      return required ? Promise.reject(errorMessages.required) : Promise.resolve();
    }

    if (!condition.test(value)) {
      return Promise.reject(errorMessages.phoneError);
    }

    return Promise.resolve();
  };
};

