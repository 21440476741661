import {useDocumentTitle} from "shared/helpers/hooks/document-title";
import {useScrollToTop} from "shared/helpers/hooks/use-scroll-to-top";
import type {AppPageProps} from "./Page.types";
import {AppContent} from "./Content";

export const AppPage = (props: AppPageProps) => {
  useDocumentTitle(props.documentTitle);
  useScrollToTop();
  return (
    <AppContent pageTitle={props.pageTitle}
                className={props.className}>
      {props.children}
    </AppContent>
  );
};