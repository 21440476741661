import type {MedicineOrderLocalStatus, MedicineOrderStatus} from "services/api";

export const localStatus = (
    status: MedicineOrderStatus | null
): MedicineOrderLocalStatus => {
  switch (status) {
    case "CANCELLED":
      return "Отменен";
    case "FULFILLED":
      return "Собран";
    case "COMPLETED":
      return "Завершен";
    case "HANDED_OVER_TO_DELIVERY":
      return "Передан в доставку";
    default:
      return "Новый";
  }
};