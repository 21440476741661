import {useRef} from "react";
import cn from "classnames";
import {AppCloseIcon} from "shared/UI/UIKit/icons/Close";
import {AppFlex} from "shared/UI/UIKit/Flex";
import type {AppSiderProps} from "./Sider.types";
import styles from "./Sider.module.scss";

export const AppSider = (props: AppSiderProps) => {
  const {
    isSiderVisible,
    hideSider,
    children
  } = props;

  const siderRef = useRef<HTMLDivElement | null>(null);

  return (
    <AppFlex className={styles.sider}>
      {isSiderVisible && (
        <div className={styles.mask} onClick={hideSider}/>
      )}

      <AppFlex className={cn(styles["body-wrap"], !isSiderVisible && styles.hidden)}
               unit="0" ref={siderRef}>
        <AppFlex direction="row" alignItems="center" justifyContent="space-between"
                className={styles.controls}>
          <AppCloseIcon onClick={hideSider}
                        className={styles["close-icon"]}/>
        </AppFlex>
        <AppFlex className={styles.content}>
          {children}
        </AppFlex>
      </AppFlex>
    </AppFlex>
  );
};