import styles from "./styles.module.scss";

export const PrivacyPolicyTable = () => {
  return (
    <table className={styles.table}>
      <thead>
        <th>Цели</th>
        <th>Перечень персональных данных</th>
        <th>Категории субъектов персональных данных</th>
      </thead>
      <tbody>
        <tr>
          <td>Регистрация и обслуживание личного кабинета Пользователя на Сайте, идентификация Пользователя на Сайте</td>
          <td>
            - фамилия, имя, отчество;<br/>
            - дата рождения;<br/>
            - пол;<br/>
            - адрес электронной почты;<br/>
            - контактный номер телефона;<br/>
            - адрес доставки;<br/>
            - сookies;<br/>
          </td>
          <td>Пользователи</td>
        </tr>
        <tr>
          <td>Заключение и исполнение с Пользователем договоров розничной купли-продажи</td>
          <td>
            - фамилия, имя, отчество;<br/>
            - адрес электронной почты;<br/>
            - контактный номер телефона;<br/>
            - адрес доставки;<br/>
            - реквизиты заказа;<br/>
            - сookies;<br/>
          </td>
          <td>Пользователи</td>
        </tr>
        <tr>
          <td>Оказание услуг по изготовлению и отпуску лекарственного препарата по рецепту </td>
          <td>
            - фамилия, имя, отчество;<br/>
            - дата рождения;<br/>
            - адрес электронной почты;<br/>
            - контактный номер телефона;<br/>
            - адрес доставки;<br/>
            - реквизиты заказа;<br/>
            - сookies;<br/>
          </td>
          <td>Пользователи</td>
        </tr>
        <tr>
          <td>Обеспечение связи с Пользователем в рамках исполнения заказов</td>
          <td>
            - фамилия, имя, отчество;<br/>
            - адрес электронной почты;<br/>
            - контактный номер телефона;<br/>
            - адрес доставки;<br/>
            - реквизиты заказа;<br/>
            - сookies;<br/>
          </td>
          <td>Пользователи</td>
        </tr>
        <tr>
          <td>Подготовка ответов на обращения Пользователей, направленных при помощи формы обратной связи на сайте</td>
          <td>
            - фамилия, имя, отчество; <br/>
            - дата рождения; <br/>
            - пол; <br/>
            - адрес электронной почты; <br/>
            - контактный номер телефона; <br/>
            - адрес доставки; <br/>
            - реквизиты заказа; <br/>
            - сookies; <br/>
          </td>
          <td>Пользователи</td>
        </tr>
        <tr>
          <td>Направление Пользователю информационных сообщений и любой иной информации рекламного характера</td>
          <td>
            - фамилия, имя, отчество; <br/>
            - дата рождения; <br/>
            - пол; <br/>
            - адрес электронной почты; <br/>
            - контактный номер телефона; <br/>
            - реквизиты заказа; <br/>
          </td>
          <td>Пользователи</td>
        </tr>
        <tr>
          <td>Улучшение функциональности Сайта и удобства его использования;</td>
          <td>
            - сookies;
          </td>
          <td>Пользователи</td>
        </tr>
      </tbody>
    </table>
  );
};