import {useEffect} from "react";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {useSelectionService} from "shared/helpers/hooks/selection";
import type {ChooseMedicineModalProps} from "./types";
import {selectPrescriptionOrderItem} from "../../redux/selectors";
import {loadMedicineByInnNameAction} from "../../redux/actions";
import {addMedicineToOrderItemAction} from "../../redux/slice";

export const useChooseMedicineModal = (props: ChooseMedicineModalProps) => {
  const selectedOrderItem = useAppSelector(selectPrescriptionOrderItem);
  const dispatch = useAppDispatch();
  const selectionService = useSelectionService();

  useEffect(() => {
    if (selectedOrderItem) {
      dispatch(loadMedicineByInnNameAction(selectedOrderItem.innName));
      const selectedMedicineId = selectedOrderItem.medicineDTO?.id;
      if (selectedMedicineId) {
        selectionService.methods.setSelectedKeys([selectedMedicineId]);
      }
    }

    return () => {
      selectionService.methods.clear();
    };
  }, [selectedOrderItem]);

  const addSelectedMedicineToOrderItem = () => {
    const selectedMedicineId: number | null = (selectionService.state.selectedKeys[0] as number) || null;
    dispatch(addMedicineToOrderItemAction(selectedMedicineId));
    props.hide();
  };

  return {
    addSelectedMedicineToOrderItem,
    selectionService,
    selectedOrderItem,
  };
};