import {isFunction} from "shared/helpers/utils/is-function";
import type {AppFilter} from "../../../../lib/filters/types";
import type {AppTableItem} from "../../../types/table.types";
import type {AppTableSingleColumn} from "../../../types/column.types";

export const getFilterWithDefaultOptions = <TRecord extends AppTableItem>(
  filter: AppFilter,
  column: AppTableSingleColumn<TRecord>,
): AppFilter => {
  const {
    placeholder = getDefaultPlaceholder(column),
    placeholderStart = "От",
    placeholderEnd = "До",
    ...restOptions
  } = filter.options ?? {};

  return {
    ...filter,
    options: {
      placeholder,
      placeholderStart,
      placeholderEnd,
      ...restOptions,
    }
  };
};

const getDefaultPlaceholder = <TRecord extends AppTableItem>(
  column: AppTableSingleColumn<TRecord>,
): string => {
  const placeholderNode = isFunction(column.title) ? column.title({}) : column.title;
  return `${placeholderNode}`;
};