import {createPipe, PIPE_TYPE} from "imask";
import type {FactoryArg} from "imask";

export const getMask = (mask: FactoryArg) => {
  return {
    masked: (value: string) => {
      const IMask = createPipe(
        mask,
        PIPE_TYPE.MASKED,
      );
      return IMask(value);
    },
    unmasked: (value: string) => {
      const IMask = createPipe(
        mask,
        PIPE_TYPE.MASKED,
        PIPE_TYPE.UNMASKED,
      );
      return IMask(value);
    },
  };
};