import type {PaginationAndSort} from "../types";

export type LoadOrderRequestBody = {
  name: string;
  dateOfBirth: string;
  prescriptionNumber: string;
}

export type OrdersListRequestParams = PaginationAndSort & {
  phone?: string;
  address?: string;
  status?: MedicineOrderStatus;
}

export type Medicine = {
  id: number;
  zipInfo: string;
  gtin: string;
  name: string;
  price: number;
  imageId: number | null;
  information: string;
  manufacturer: string;
  storageTime: string;
  storageConditions: string;
  instructions: string;
  balances: MedicineBalance[];
}

export type MedicineBalance = {
  storeId: string;
  name: string;
  balance: number;
}

export type MedicineOrder = {
  id: number | null;
  address: string;
  phone: string;
  prescriptionNumber: string;
  fio: string;
  emiasId: string | null;
  delivery?: boolean;
  status: MedicineOrderStatus;
  birthDate: number;
  prescriptionDate: string | null;
  items: MedicineOrderItem[];
}

export enum MedicineOrderStatus {
  CANCELLED = "CANCELLED",
  FULFILLED = "FULFILLED",
  COMPLETED = "COMPLETED",
  HANDED_OVER_TO_DELIVERY = "HANDED_OVER_TO_DELIVERY",
  NEW = "NEW"
}

export type MedicineOrderLocalStatus = "Завершен" | "Отменен" | "Собран" | "Передан в доставку" | "Новый";

export type MedicineOrderItem = {
  id: number | null;
  innName: string;
  esklp: string;
  amount: number | null;
  pricePerUnit: number | null;
  totalPrice: number | null;
  zipInfo: string;
  gtin: string;
  medicineDTO: Medicine | null;
  information: string,
  manufacturer: string,
  storageTime: string,
  storageConditions: string,
  instructions: string,
  egk: string;
  prescriptionNumber: string;
}
