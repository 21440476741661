import {buildUrl} from "shared/helpers/utils/url-builder";
import type {
  ApothecaryOrderPageParams,
  getUrlFnType,
  LoadOrderByGuidPageSearchParams,
  PrescriptionOrderPageSearchParams
} from "./types";
import {appPagePaths} from "./urls";

export const getHomeUrl: getUrlFnType = () => appPagePaths.home;
export const getPrescriptionOrderUrl: getUrlFnType<PrescriptionOrderPageSearchParams> = (searchParams) => buildUrl(appPagePaths.prescriptionOrder, {}, searchParams);
export const getApothecaryUrl: getUrlFnType = () => appPagePaths.apothecary;
export const getAboutUrl: getUrlFnType = () => appPagePaths.about;
export const getTermsOfUseUrl: getUrlFnType = () => appPagePaths.termsOfUse;
export const getTermsOfSaleUrl: getUrlFnType = () => appPagePaths.termsOfSale;
export const getPrivacyPolicyUrl: getUrlFnType = () => appPagePaths.privacyPolicy;
export const getApothecaryOrderUrl: getUrlFnType<ApothecaryOrderPageParams> = (params) => buildUrl(appPagePaths.apothecaryOrder, params);

export const getLoadOrderByGuidUrl: getUrlFnType<LoadOrderByGuidPageSearchParams> = (searchParams) => buildUrl(appPagePaths.loadOrderByGuid, {}, searchParams);
