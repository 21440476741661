import type {AppInputRef} from "shared/UI/UIKit/Input";
import type {FilterDropdownProps} from "../../types";
import type {TextFilterFormValue} from "./types";
import {useFilterDropdown} from "../../hooks/use-filter-dropdown/hook";
import {FilterForm} from "../FilterForm/FilterForm";
import {TextFilterFields} from "./filter-fields/TextFilterFields";
import {getInitialFormValues, getFilterToSubmit, getFilterToReset} from "./TextFilterDropdown.utils";

export const TextFilterDropdown = (props: FilterDropdownProps) => {
  const {form, focusRef, onSubmit, onReset} = useFilterDropdown<TextFilterFormValue, AppInputRef>({
    ...props, getInitialFormValues, getFilterToSubmit, getFilterToReset,
  });

  return (
    <FilterForm form={form} filterType={props.filter.type}
                onSubmit={onSubmit} onReset={onReset}>
      <TextFilterFields form={form} focusRef={focusRef}
                        options={props.filter.options}/>
    </FilterForm>
  );
};