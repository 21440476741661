import {AppFlex} from "shared/UI/UIKit/Flex";
import {FullPageLoader} from "shared/UI/UIKit/Loader";
import {MedicineList} from "../MedicineList/MedicineList";
import {ApothecaryOrderControls} from "../Controls/Controls";
import {ApothecaryOrderDescription} from "../Description/Description";
import {BackButton} from "../BackButton/BackButton";
import {useApothecaryOrder} from "./use-apothecary-order";
import styles from "./styles.module.scss";

export const ApothecaryOrder = () => {
  const {
    loading,
    order,
    ready,
    inDelivery,
    canceled
  } = useApothecaryOrder();

  if (loading) {
    return <FullPageLoader/>;
  }

  if (!order) {
    return null;
  }

  return (
    <AppFlex className={styles.flex} alignItems="flex-start">
      <BackButton/>
      <ApothecaryOrderDescription order={order}/>
      <MedicineList items={order.items}/>
      <ApothecaryOrderControls status={order!.status}
                               ready={ready}
                               inDelivery={inDelivery}
                               canceled={canceled}/>
    </AppFlex>
  );
};