import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppText} from "shared/UI/UIKit/Text";
import type {MedicineOrder} from "services/api";
import {PrescriptionOrderItemCard} from "../PrescriptionOrderItem/PrescriptionOrderItem";

type PrescriptionOrderItemsListProps = {
  prescriptionOrder: MedicineOrder | null;
  openChooseMedicineModal: (id: number) => void;
  changeAmount: (value: number | null, index: number) => void;
}

export const PrescriptionOrderItemsList = (props: PrescriptionOrderItemsListProps) => {
  const {
    prescriptionOrder,
    openChooseMedicineModal,
  } = props;

  if (!prescriptionOrder) {
    return null;
  }

  if (!prescriptionOrder.items?.length) {
    return (
      <AppFlex alignItems="center">
        <AppText weight={700}>
          По данному рецепту ничего не найдено
        </AppText>
      </AppFlex>
    );
  }

  const list = prescriptionOrder.items?.map((item, index) => {
    const changeAmount = (value: number | null) => {
      props.changeAmount(value, index);
    };

    return (
      <PrescriptionOrderItemCard chooseMedicine={openChooseMedicineModal}
                                 changeAmount={changeAmount}
                                 orderItem={item}
                                 key={item.esklp}/>
    );
  });

  return (
    <AppFlex>
      {list}
    </AppFlex>
  );
};