import {getOrderById, orderCanceled, orderInDelivery, orderReady} from "services/api";
import {createAppAsyncThunk} from "services/redux/types";
import type {AxiosError} from "axios";

export const getOrderByIdAction = createAppAsyncThunk("order/get", async(id: number | string) => {
  try {
    return await getOrderById(id);
  } catch (e: unknown) {
    throw e as AxiosError;
  }
});

export const setOrderReadyAction = createAppAsyncThunk("order/ready", async(_, thunkApi) => {
  const {order} = thunkApi.getState().orderState;
  try {
    return await orderReady(order!.id!);
  } catch (e: unknown) {
    throw e as AxiosError;
  }
});

export const setOrderCanceledAction = createAppAsyncThunk("order/canceled", async(_, thunkApi) => {
  const {order} = thunkApi.getState().orderState;
  try {
    return await orderCanceled(order!.id!);
  } catch (e: unknown) {
    throw e as AxiosError;
  }
});

export const setOrderInDeliveryAction = createAppAsyncThunk("order/in_delivery", async(_, thunkApi) => {
  const {order} = thunkApi.getState().orderState;
  try {
    return await orderInDelivery(order!.id!);
  } catch (e: unknown) {
    throw e as AxiosError;
  }
});