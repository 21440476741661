import {showTotal} from "shared/UI/UIKit/Pagination";
import type {RequiredType} from "shared/types";
import type {AppFilter} from "../lib/filters/types";
import type {TableStateManagerState} from "../lib/state-manager/types";
import type {AppTableSorter} from "../ui/types/sorter.types";
import type {AppTablePagination} from "../ui/types/table.types";

export const DEFAULT_TABLE_SORTER_WEIGHT = 0;

export const getDefaultTableSorters = (): AppTableSorter[] => [];

export const getDefaultTableFilters = (): AppFilter[] => [];

type DefaultTablePagination = RequiredType<AppTablePagination,
  "total" |
  "current" |
  "pageSize" |
  "defaultPageSize" |
  "position" |
  "showSizeChanger" |
  "hideOnSinglePage" |
  "showTotal">;

export const getDefaultTablePagination = (): DefaultTablePagination => ({
  total: 0,
  current: 1,
  pageSize: 10,
  defaultPageSize: 10,
  position: ["bottomRight"],
  showSizeChanger: false,
  hideOnSinglePage: true,
  showTotal,
});

export const getDefaultTableState = (): TableStateManagerState => ({
  sorters: getDefaultTableSorters(),
  filters: getDefaultTableFilters(),
  pagination: getDefaultTablePagination(),
});