import {appApi} from "services/axios";
import type {ListResponse} from "services/api/types";
import type {LoadOrderRequestBody, MedicineOrder, Medicine} from "./types";
import type {AppTableChangeEvent} from "shared/UI/UIKit/Table";
import {appErrorHandler} from "../error-handler";

export const loadOrder = (body: LoadOrderRequestBody): Promise<MedicineOrder> => {
  return appApi
    .post("/orders/load_order", body)
    .then(res => res.data)
    .catch(appErrorHandler);
};

export const getMedicines = (innName: string): Promise<Medicine[]> => {
  return appApi
    .get(`/medicines/find_medicines/${innName}`)
    .then(res => res.data)
    .catch(appErrorHandler);
};

export const sendOrder = (body: MedicineOrder): Promise<MedicineOrder> => {
  return appApi
    .post("/orders/send_order", body)
    .then(res => res.data)
    .catch((e) => appErrorHandler(e, "Заказа не может быть доставлен"));
};

export const getOrders = (event: AppTableChangeEvent): Promise<ListResponse<MedicineOrder>> => {
  return appApi
    .get("/orders/list", {
      params: event,
      paramsSerializer: {indexes: null}
    })
    .then(res => {
      return {
        data: res.data,
        total: Number(res.headers["totalelements"]) || 0,
      };
    })
    .catch(appErrorHandler);
};

export const getOrderById = (id: number | string): Promise<MedicineOrder> => {
  return appApi
    .get(`/client/order/${id}`)
    .then(res => res.data)
    .catch(appErrorHandler);
};


export const orderReady = (orderId: string | number): Promise<MedicineOrder> => {
  return appApi
    .post(`/orders/fulfill/${orderId}`)
    .then(res => res.data)
    .catch(appErrorHandler);
};

export const orderCanceled = (orderId: string | number): Promise<MedicineOrder> => {
  return appApi
    .post(`/orders/cancel/${orderId}`)
    .then(res => res.data)
    .catch(appErrorHandler);
};

export const orderInDelivery = (orderId: string | number): Promise<MedicineOrder> => {
  return appApi
    .post(`/orders/handover_to_delivery/${orderId}`)
    .then(res => res.data)
    .catch(appErrorHandler);
};

export const loadOrderByGuid = (guid: string): Promise<MedicineOrder> => {
  return appApi
    .post(`/orders/load_order_by_guid/${guid}`)
    .then(res => res.data)
    .catch(appErrorHandler);
};