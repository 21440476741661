import {AppPage} from "common/components/Layout";
import {StaticContent} from "features/StaticContent/StaticContent";
import {items} from "./items";

export const AboutPage = () => {
  return (
    <AppPage pageTitle={"О нас"}>
      <StaticContent items={items}/>
    </AppPage>
  );
};