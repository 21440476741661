import {StaticDataPagesItemType} from "features/StaticContent/types";
import type {StaticDataPagesItem} from "features/StaticContent/types";

export const items: StaticDataPagesItem[] = [
  {
    type: StaticDataPagesItemType.TITLE,
    content: "Полное наименование аптечной организации:",
    align: "left",
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "Государственное бюджетное учреждение здравоохранения города Москвы «Центр лекарственного обеспечения Департамента здравоохранения города Москвы»",
  },
  {
    type: StaticDataPagesItemType.TITLE,
    content: "Основной государственный регистрационный номер (ОГРН):",
    align: "left"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "1027700400100"
  },
  {
    type: StaticDataPagesItemType.TITLE,
    content: "Идентификационный номер плательщика (ИНН):",
    align: "left"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "7720023269"
  },
  {
    type: StaticDataPagesItemType.TITLE,
    content: "Юридический адрес организации:",
    align: "left",
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "127018, г. Москва, ул. Стрелецкая, д. 3, стр. 1"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "Режим работы:"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "пн 8.30-17.15"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "вт 8.30-17.15"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "ср 8.30-17.15"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "чт 8.30-17.15"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "пт 8.30-16.00"
  },
  {
    type: StaticDataPagesItemType.TITLE,
    content: "Адреса структурных подразделений (адреса мест осуществления деятельности):",
    align: "left",
  },
  {
    type: StaticDataPagesItemType.TITLE,
    content: "- Аптека № 5, г. Москва, ул. Петровка, д. 19,стр.1",
    align: "left",
    level: 4,
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "Режим работы"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "Пн-Пт: 08.00-21.00"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "Субб: 09.00-20.00"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "Воскр: 10.00-18.00"
  },
  {
    type: StaticDataPagesItemType.TITLE,
    content: "- Аптека № 17, г. Москва, Дмитровское ш, д. 37, корп.1",
    align: "left",
    level: 4,
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "Режим работы"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "Круглосуточно"
  },
  {
    type: StaticDataPagesItemType.TITLE,
    content: "- Аптека № 32, г. Москва, ул. Большая Черкизовская, д. 8, корп.1",
    align: "left",
    level: 4,
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "Режим работы"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "Круглосуточно"
  },
  {
    type: StaticDataPagesItemType.TITLE,
    content: "- Аптека № 40, г. Москва, ул. Судакова, д. 15",
    align: "left",
    level: 4,
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "Режим работы"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "Пн-Пт: 08.00-22.00"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "Субб: 09.00-20.00"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "Воскр: 09.00-20.00"
  },
  {
    type: StaticDataPagesItemType.TITLE,
    content: "- Аптека № 61, г. Москва, ул. Крылатские Холмы, д.34",
    align: "left",
    level: 4,
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "Режим работы"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "Пн-Пт: 08.00-22.00"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "Субб: 09.00-20.00"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "Воскр: 10.00-18.00"
  },
  {
    type: StaticDataPagesItemType.TITLE,
    content: "Регистрационный номер лицензии на осуществление фармацевтической деятельности:",
    align: "left",
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "Л042-01137-77/00392533 от 25.12.2020"
  },
  {
    type: StaticDataPagesItemType.TITLE,
    content: "Сведения об уполномоченных федеральных органах исполнительной власти, осуществляющих контроль за осуществлением розничной торговли лекарственными препаратами дистанционными способами:",
    align: "left",
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "Министерство здравоохранения РФ"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "Адрес:"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "127994, ГСП-4, г. Москва, Рахмановский пер, д. 3"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "Телефон «горячей линии»:"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "+7 (800) 200-03-89"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "Телефоны Справочной службы:"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "+7 (495) 628-44-53"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "+7 (495) 627-29-44"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "Многоканальный телефон:"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "+7 (495) 627-24-00"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "Телефон для информирования о факте регистрации обращений граждан:"
  },
  {
    type: StaticDataPagesItemType.TEXT,
    content: "+7 (495) 627-29-93"
  },
];