import {AppFlex} from "shared/UI/UIKit/Flex";
import {getDownloadFileUrl} from "services/api";
import {createHTMLMarkup} from "shared/helpers/utils/createHtmlMarkup";
import type {AppTableColumn} from "shared/UI/UIKit/Table";
import type {Medicine} from "services/api";
import styles from "./styles.module.scss";

export const columns: AppTableColumn<Medicine>[] = [
  {
    title: "Название",
    dataIndex: ["name"],
    key: "name",
    width: "20rem",
    render: (value: string) => {
      return (
        <div dangerouslySetInnerHTML={createHTMLMarkup(value)}/>
      );
    }
  },
  {
    title: "Информация",
    dataIndex: ["zipInfo"],
    key: "zipInfo",
    width: "40rem",
    render: (value: string) => {
      return (
        <div dangerouslySetInnerHTML={createHTMLMarkup(value)}/>
      );
    }
  },
  {
    title: "Цена руб.",
    dataIndex: ["price"],
    key: "price",
    width: "12rem",
  },
  {
    title: "Изображение",
    dataIndex: ["imageId"],
    key: "imageId",
    width: "25rem",
    render: (value: number, record: Medicine) => {
      if (!value) return null;

      return (
        <AppFlex alignItems="center" justifyContent="center">
          <img className={styles.image}
               alt={record.name}
               src={getDownloadFileUrl(value)}/>
        </AppFlex>
      );
    }
  },
];