import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppButton} from "shared/UI/UIKit/Button";
import type {MedicineOrderStatus} from "services/api";

type ApothecaryOrderControlsProps = {
  status?: MedicineOrderStatus;
  ready: () => void;
  inDelivery: () => void;
  canceled: () => void;
}

export const ApothecaryOrderControls = (props: ApothecaryOrderControlsProps) => {
  const readyBtn = <AppButton key={"1"} onClick={props.ready}>Собран</AppButton>;
  const deliveryBtn = <AppButton key={"2"} onClick={props.inDelivery}>Выдан курьеру</AppButton>;
  const cancelBtn = <AppButton key={"3"} onClick={props.canceled}>Отменен</AppButton>;

  const getButtons = () => {
    const buttons = [];
    if (!props.status) {
      buttons.push(readyBtn, cancelBtn);
    } else if (props.status === "FULFILLED") {
      buttons.push(deliveryBtn, cancelBtn);
    } else if (props.status === "HANDED_OVER_TO_DELIVERY") {
      buttons.push(cancelBtn);
    }
    return buttons;
  };

  return (
    <AppFlex direction="row">
      {getButtons()}
    </AppFlex>
  );
};