import {AppFilterIcon} from "shared/UI/UIKit/icons/Filter";
import type {AppFilter} from "../../../../lib/filters/types";
import type {AppTableItem} from "../../../types/table.types";
import type {AppTableSingleColumn} from "../../../types/column.types";
import type {FilterableColumn} from "../types";
import {AppFilterDropdown} from "../../../../lib/filters/FilterDropdown/FilterDropdown";
import {getFilterWithDefaultOptions} from "./utils";
import styles from "./styles.module.scss";

export const addFilterToColumn = <TRecord extends AppTableItem>(
  column: AppTableSingleColumn<TRecord>,
  filters: AppFilter[],
  onFilterChange: (filter: AppFilter) => void,
  visibleFilter: string,
  setVisibleFilter: (value: string) => void,
): FilterableColumn<TRecord> => {
  const matchedFilter = filters.find(filterItem => filterItem.key === column.key);
  if (!matchedFilter) {
    return column;
  }

  /*
    non-empty filter.values will be interpreted as filtered
    - set filter.values equal to undefined or empty list to set column unfiltered
    - set filter.values equal to list with undefined/null elements to set column filtered with no values (for cases when values are not needed)
    - set filter.values equal to list with any type of elements to set column filtered with values
   */
  const filtered = !!matchedFilter.operator && !!matchedFilter.values?.length;

  return {
    ...column,
    filterDropdownOpen: visibleFilter === column.key,
    onFilterDropdownOpenChange: (visible: boolean) => {
      if (visible) {
        setVisibleFilter(column.key);
      } else {
        setVisibleFilter("");
      }
    },
    filterDropdown: ({confirm}) => {
      const visible = visibleFilter === column.key;

      return (
        <AppFilterDropdown filter={getFilterWithDefaultOptions(matchedFilter, column)}
                           visible={visible}
                           close={() => confirm({closeDropdown: true})}
                           onChange={onFilterChange}/>
      );
    },
    filtered,
    filterIcon: <AppFilterIcon className={filtered ? styles["column-filter--active"] : undefined}/>,
  };
};