import React, {useContext, useEffect, useRef, useState} from "react";
import {AppFormItem, formRules} from "shared/UI/UIKit/Form";
import {AppInput} from "shared/UI/UIKit/Input";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppCloseIcon} from "shared/UI/UIKit/icons/Close";
import {getByPath} from "shared/helpers/utils/get-by-path";
import type {EditableCellProps} from "./EditableCell.types";
import type {AppInputRef} from "shared/UI/UIKit/Input";
import type {AppTableItem} from "../../../../types/table.types";
import {EditableContext} from "../EditableContext/EditableContext";

export const EditableCell = <TRecord extends AppTableItem>(
  {
    dataIndex,
    record,
    editable,
    handleSave,
    children,
    ...rest
  }: EditableCellProps<TRecord>
) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<AppInputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex.join(".")]: String(getByPath(record, dataIndex)),
    });
  };

  const save = () => {
    form.validateFields().then(values => {
      handleSave({...record, ...values});
      toggleEdit();
    });
  };

  const onDoubleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleEdit();
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <AppFlex onClick={e => e.stopPropagation()} direction="row" alignItems="center">
        <AppFormItem marginless withoutExplain name={dataIndex.join(".")} rules={formRules.required}>
          <AppInput ref={inputRef} onPressEnter={save} onBlur={save}/>
        </AppFormItem>
        <AppCloseIcon onClick={toggleEdit}/>
      </AppFlex>
    ) : (
      <div onClick={e => e.stopPropagation()} onDoubleClick={onDoubleClick}>
        {children}
      </div>
    );
  }

  return <td {...rest}>{childNode}</td>;
};