import type {FilterDropdownProps} from "../../types";
import type {EnumFilterFormValue} from "./types";
import {useFilterDropdown} from "../../hooks/use-filter-dropdown/hook";
import {FilterForm} from "../FilterForm/FilterForm";
import {EnumFilterFields} from "./filter-fields/EnumFilterFields";
import {getInitialFormValues, getFilterToSubmit, getFilterToReset} from "./EnumFilterDropdown.utils";

export const EnumFilterDropdown = (props: FilterDropdownProps) => {
  const {form, onSubmit, onReset} = useFilterDropdown<EnumFilterFormValue>({
    ...props, getInitialFormValues, getFilterToSubmit, getFilterToReset,
  });

  return (
    <FilterForm form={form} filterType={props.filter.type}
                onSubmit={onSubmit} onReset={onReset}>
      <EnumFilterFields form={form} options={props.filter.options}/>
    </FilterForm>
  );
};