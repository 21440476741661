import {createSelector} from "@reduxjs/toolkit";
import type {MedicineOrderItem} from "services/api";
import type {RootState} from "services/redux/types";
import type {PrescriptionOrderState} from "../redux/types";

export const selectPrescriptionOrderState = (state: RootState) => state.prescriptionOrderState;

export const selectPrescriptionOrderItem = createSelector([selectPrescriptionOrderState], (state: PrescriptionOrderState): MedicineOrderItem | undefined => {
  const {selectedPrescriptionOrderItemId, prescriptionOrder} = state;
  return prescriptionOrder?.items?.find(item => item.id === selectedPrescriptionOrderItemId);
});

export const selectPrescriptionOrderItemsWithAmount = createSelector([selectPrescriptionOrderState], (state: PrescriptionOrderState): MedicineOrderItem[] => {
  return state.prescriptionOrder?.items?.filter(item => item.amount) || [];
});