import {useEffect, useState} from "react";
import type {ChangeEvent} from "react";
import type {FactoryArg} from "imask";
import {getMask} from "./use-input-masking.utils";

export const useInputMasking = (
  mask: FactoryArg,
  inputValue?: string | number,
  onChange?: (event: ChangeEvent<HTMLInputElement>) => any,
) => {
  const {masked, unmasked} = getMask(mask);
  const [maskedValue, setMaskedValue] = useState<string>();

  useEffect(() => {
    if (inputValue) {
      setMaskedValue(masked(inputValue.toString()));
    }
  }, [inputValue]);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {value} = event.currentTarget;
    event.target.value = masked(value);
    setMaskedValue(masked(value));

    if (onChange) {
      event.target.value = unmasked(value);
      const values = {
        unmaskedValue: unmasked(value),
        maskedValue,
      };
      Object.assign(event, values);
      onChange(event);
    }
  };

  return {
    onInputChange,
    maskedValue,
  };
};