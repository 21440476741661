import {AppModal} from "shared/UI/UIKit/Modal";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {useAppForm} from "shared/UI/UIKit/Form";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import type {MedicineOrderItem} from "services/api";
import type {AddressFormValues} from "./AddressForm/types";
import {selectPrescriptionOrderItemsWithAmount, selectPrescriptionOrderState} from "../../redux/selectors";
import {createPrescriptionOrderAction} from "../../redux/actions";
import {clearPrescriptionOrderAction} from "../../redux/slice";
import {ModalFooter} from "./ModalFooter/ModalFooter";
import {ModalContent} from "./ModalContent/ModalContent";

type CreateOrderModalProps = {
  hide: () => void;
  visible: boolean;
  resetFormFields: () => void;
};

export const CreateOrderModal = (props: CreateOrderModalProps) => {
  const filteredItems: MedicineOrderItem[] = useAppSelector(selectPrescriptionOrderItemsWithAmount);
  const {prescriptionOrder, loading} = useAppSelector(selectPrescriptionOrderState);
  const [form] = useAppForm<AddressFormValues>();
  const dispatch = useAppDispatch();

  const hideModal = () => {
    if (prescriptionOrder?.emiasId) {
      scrollTo(0, 0);
      dispatch(clearPrescriptionOrderAction());
      props.resetFormFields();
    }
    props.hide();
    form.resetFields();
  };

  const submit = () => {
    form.validateFields().then(values => {
      dispatch(createPrescriptionOrderAction(values))
        .unwrap()
        .then(() => {
          form.resetFields();
        })
        .catch(() => console.error("Произошла ошибка"));
    }).catch(() => {
      console.error("Необходимо заполнить все поля");
    });
  };

  const content = <ModalContent order={prescriptionOrder}
                                            filteredItems={filteredItems}
                                            form={form}/>;
  const footer = <ModalFooter close={hideModal}
                                          emiasId={prescriptionOrder?.emiasId}
                                          submit={submit}/>;

  const getModalTitle = () => {
    return prescriptionOrder?.emiasId ?
      "Заказ успешно оформлен" :
      "Проверьте ваш заказ и заполните необходимые поля";
  };

  return (
    <AppModal content={content} footer={footer}
              destroyOnClose={true}
              loading={loading}
              onCancel={hideModal}
              title={getModalTitle()}
              visible={props.visible}/>
  );
};