import {AppFlex} from "shared/UI/UIKit/Flex";
import {createHTMLMarkup} from "shared/helpers/utils/createHtmlMarkup";
import type {MedicineOrderItem} from "services/api";
import {getOrderItemContent} from "./utils";

type OrderInfoItemProps = {
  orderNumber: number;
  item: MedicineOrderItem;
}

export const OrderInfoItem = (props: OrderInfoItemProps) => {
  const orderItemContent = getOrderItemContent(props.item, props.orderNumber);

  return (
    <AppFlex key={props.item.innName} direction="row" alignItems="center">
      <div dangerouslySetInnerHTML={createHTMLMarkup(orderItemContent)}/>
    </AppFlex>
  );
};
