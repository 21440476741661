import {AppNavLink} from "shared/UI/UIKit/Link";
import {getAboutUrl, getPrivacyPolicyUrl, getTermsOfSaleUrl, getTermsOfUseUrl} from "services/navigation";
import type {AppNavLinkProps} from "shared/UI/UIKit/Link";
import styles from "./Footer.module.scss";

export const footerNavItems: AppNavLinkProps[] = [
  {
    to: getPrivacyPolicyUrl(),
    children: "Политика конфиденциальности",
  },
  {
    to: getTermsOfSaleUrl(),
    children: "Условия продажи",
  },
  {
    to: getTermsOfUseUrl(),
    children: "Пользовательское соглашение",
  },
  {
    to: getAboutUrl(),
    children: "О нас",
  }
];

export const AppFooter = () => {
  return (
    <footer className={styles.footer}>
      {
        footerNavItems.map((item, index) => {
          return (
            <AppNavLink key={index} to={item.to}
                        className={styles["nav-item"]}>
              {item.children}
            </AppNavLink>
          );
        })
      }
    </footer>
  );
};
