import {Outlet} from "react-router-dom";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {isAdminSelector, isProvisorSelector} from "services/profile/redux/selectors";
import {PageProtectorBase} from "./Base/PageProtectorBase";

export const ProvisorPageProtector = () => {
  const isProvisor = useAppSelector(isProvisorSelector);
  const isAdmin = useAppSelector(isAdminSelector);

  return (
    <PageProtectorBase to={"/"} condition={isProvisor || isAdmin}>
      <Outlet/>
    </PageProtectorBase>
  );
};