import {AppFlex} from "shared/UI/UIKit/Flex";
import {PrescriptionForm} from "../PrescriptionForm/PrescriptionForm";
import {PrescriptionOrderItemsList} from "../PrescriptionOrderItemsList/PrescriptionOrderItemsList";
import {CreatePrescriptionOrderButton} from "../CreateOrderButton/CreateOrderButton";
import {ChooseMedicineModal} from "../ChooseMedicineModal/ChooseMedicineModal";
import {CreateOrderModal} from "../CreateOrderModal/CreateOrderModal";
import styles from "./styles.module.scss";
import {usePrescriptionOrder} from "./use-prescription-order";

export const PrescriptionOrder = () => {
  const {state, methods} = usePrescriptionOrder();

  return (
    <AppFlex alignItems="center" className={styles.container}>
      <div className={styles.wrapper}>
        <PrescriptionForm loading={state.loading} submit={methods.submit}
                          form={state.form}/>
        <AppFlex>
          <PrescriptionOrderItemsList prescriptionOrder={state.prescriptionOrder}
                                      openChooseMedicineModal={methods.openChooseMedicineModal}
                                      changeAmount={methods.changeAmount}/>
          <CreatePrescriptionOrderButton prescriptionOrder={state.prescriptionOrder}
                                         openCreateOrderModal={methods.openCreateOrderModal}/>
        </AppFlex>
        <ChooseMedicineModal visible={state.medicineModalVisible}
                             hide={methods.closeChooseMedicineModal}/>
        <CreateOrderModal visible={state.orderModalVisible}
                          hide={methods.closeOrderModal}
                          resetFormFields={state.form.resetFields}/>
      </div>
    </AppFlex>
  );
};