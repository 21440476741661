import {createSlice} from "@reduxjs/toolkit";
import type {ProfileState} from "./types";
import {getProfileReducerBuilder} from "./reducers";

const initialState: ProfileState = {
  profile: null,
  loading: true,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    clearProfile: (state: ProfileState) => {
      state.profile = null;
    }
  },
  extraReducers: (builder) => {
    getProfileReducerBuilder(builder);
  }
});

export const {clearProfile: clearProfileAction} = profileSlice.actions;
export const profileReducer = profileSlice.reducer;