import {AppFlex} from "shared/UI/UIKit/Flex";
import type {MedicineOrderItem} from "services/api";
import {OrderInfoItem} from "./OrderInfoItem/OrderInfoItem";
import {getFullPriceForOrder} from "./utils";

type OrderInfoProps = {
  orderItemsWithAmount: MedicineOrderItem[];
}

export const OrderInfo = ({orderItemsWithAmount}: OrderInfoProps) => {
  const list = orderItemsWithAmount.map((item: MedicineOrderItem, index: number) => {
    return <OrderInfoItem item={item} orderNumber={index + 1} key={item.id}/>;
  });

  return (
    <AppFlex>
      {list}
      <div>
        <b>Общая стоимость:</b> {getFullPriceForOrder(orderItemsWithAmount)} руб.
      </div>
    </AppFlex>
  );
};