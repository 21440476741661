import {appApi} from "services/axios";
import type {UserProfile} from "./types";
import {appErrorHandler} from "../error-handler";

export const getProfile = (): Promise<UserProfile> => {
  return appApi
    .get("/current_profile")
    .then(res => res.data)
    .catch(appErrorHandler);
};
