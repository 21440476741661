import React from "react";
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import {App} from "app";
import {store} from "services/redux/store";
import "./services/dayjs";
import {recreateAxiosInstance} from "services/axios";

const root = createRoot(document.getElementById("root")!);
recreateAxiosInstance(store);

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App/>
    </React.StrictMode>
  </Provider>
);
