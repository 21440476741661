import {shallowEqual} from "shared/helpers/utils/shallow-equal";
import type {AppTableItem} from "../../../ui/types/table.types";
import type {AppTableColumns, AppTableSingleColumn} from "../../../ui/types/column.types";
import {isTableColumnGroup} from "../../../ui/Table.utils";

export const getDataIndexedColumns = <TRecord extends AppTableItem>(
  columns: AppTableColumns<TRecord>, uniqueDataIndex = false,
): AppTableSingleColumn<TRecord>[] => {
  const result: AppTableSingleColumn<TRecord>[] = [];

  const recursivelyPushSingleColumns = (cols: AppTableColumns<TRecord>) => {
    cols.forEach(col => {
      if (isTableColumnGroup(col)) {
        recursivelyPushSingleColumns(col.children);
        return;
      }

      if (!uniqueDataIndex || !result.some(addedCol => shallowEqual(addedCol.dataIndex, col.dataIndex))) {
        result.push(col);
      }
   });
  };

  recursivelyPushSingleColumns(columns);

  return result;
};