import {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import dayjs from "dayjs";
import {BACK_END_DATE_FORMAT} from "shared/constants/date";
import {useAppForm} from "shared/UI/UIKit/Form";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {useModal} from "shared/UI/UIKit/Modal";
import type {LoadOrderRequestBody} from "services/api";
import type {PrescriptionOrderFormValues} from "../PrescriptionForm/types";
import {loadPrescriptionOrderAction, loadPrescriptionOrderByGuidAction} from "../../redux/actions";
import {
  clearMedicinesAction,
  clearPrescriptionOrderAction, setPrescriptionOrderItemAmountAction,
  setSelectedPrescriptionOrderItemIdAction
} from "../../redux/slice";
import {selectPrescriptionOrderState} from "../../redux/selectors";

export const usePrescriptionOrder = () => {
  const [form] = useAppForm<PrescriptionOrderFormValues>();
  const dispatch = useAppDispatch();
  const {loading, prescriptionOrder} = useAppSelector(selectPrescriptionOrderState);
  const {state: medicineModalState, methods: medicineModalMethods} = useModal();
  const {state: orderModalState, methods: orderModalMethods} = useModal();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const numbers = searchParams.get("numbers");
    if (process.env.NODE_ENV !== "production" && !numbers) {
      form.setFieldsValue({
        name: "Боринская Адолина Давыдовна",
        dateOfBirth: dayjs("10.23.1939"),
        prescriptionNumber: "00Д4519379592",
      });
    }

    if (numbers) {
      dispatch(loadPrescriptionOrderByGuidAction(numbers))
        .unwrap()
        .then(() => {
          window.scrollTo({top: 400, behavior: "smooth"});
        })
        .catch();
      searchParams.delete("numbers");
      setSearchParams(searchParams);
    }
  }, []);

  const submit = () => {
    dispatch(clearPrescriptionOrderAction());
    form.validateFields().then(values => {
      const body: LoadOrderRequestBody = {
        ...values,
        dateOfBirth: values.dateOfBirth.format(BACK_END_DATE_FORMAT),
      };
      dispatch(loadPrescriptionOrderAction(body))
        .unwrap()
        .then(() => {
          window.scrollTo({top: 400, behavior: "smooth"});
        })
        .catch(() => console.error("Произошла ошибка"));
    }).catch(() => console.error("Необходимо заполнить обязательные поля"));
  };

  const openChooseMedicineModal = (id: number) => {
    dispatch(setSelectedPrescriptionOrderItemIdAction(id));
    medicineModalMethods.show();
  };

  const closeChooseMedicineModal = () => {
    dispatch(setSelectedPrescriptionOrderItemIdAction(null));
    dispatch(clearMedicinesAction());
    medicineModalMethods.hide();
  };

  const closeOrderModal = () => {
    orderModalMethods.hide();
  };

  const changeAmount = (value: number | null, index: number) => {
    dispatch(setPrescriptionOrderItemAmountAction({value, index}));
  };

  const openCreateOrderModal = () => {
    orderModalMethods.show();
  };

  return {
    state: {
      prescriptionOrder,
      medicineModalVisible: medicineModalState.visible,
      orderModalVisible: orderModalState.visible,
      form,
      loading,
    },
    methods: {
      submit,
      openChooseMedicineModal,
      closeChooseMedicineModal,
      changeAmount,
      closeOrderModal,
      openCreateOrderModal,
    }
  };
};