import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppText} from "shared/UI/UIKit/Text";
import type {MedicineOrderItem} from "services/api";
import {MedicineListItem} from "./MedicineListItem/MedicineListItem";
import styles from "./styles.module.scss";

type MedicineListProps = {
  items: MedicineOrderItem[];
}

export const MedicineList = (props: MedicineListProps) => {
  return (
    <AppFlex className={styles.list}>
      <AppText weight={700}>Заказ</AppText>
      {
        props.items.map((item: MedicineOrderItem, index: number) => {
          return (
            <MedicineListItem key={index} item={item}/>
          );
        })
      }
    </AppFlex>
  );
};