import {Table} from "antd";
import {forwardRef} from "react";
import isempty from "lodash.isempty";
import cn from "classnames";
import {isFunction} from "shared/helpers/utils/is-function";
import type {ForwardedRef} from "react";
import type {AppTableProps, AppTableItem} from "./types/table.types";
import {useTableDataSource} from "./hooks/use-table-data-source/hook";
import {useTableChange} from "./hooks/use-table-change/hook";
import {useTableColumns} from "./hooks/use-table-columns/hook";
import {useTableRowSelection} from "./hooks/use-table-row-selection/hook";
import {useTableExpandable} from "./hooks/use-table-expandable/hook";
import {getClassNames, getRowClassNames} from "./Table.utils";
import {useEditableCells} from "./hooks/use-editable-table/hook";

const TableComponent = <TRecord extends AppTableItem>(
  props: AppTableProps<TRecord>, ref: ForwardedRef<HTMLDivElement>
) => {
  const {
    scroll = {x: "max-content", scrollToFirstRowOnChange: false},
    className,
    rowClassName,
    rowKey,
    dataSource: dataSourceProp,
    columns: columnsProp,
    onChange: onChangeProp,
    sorters = [],
    multipleSorter = false,
    showSorterTooltip = false,
    filters = [],
    pagination = {},
    paginationVisible = true,
    bordered = true,
    groupingConfig,
    rowSelection: rowSelectionProp,
    expandable: expandableProp,
    editable: editableProp,
    components: componentsProp,
    handleSave,
    ...rest
  } = props;
  const {onTableChange, onFilterChange} = useTableChange<TRecord>({
    onChange: onChangeProp,
    sorters,
    filters,
    pagination,
    paginationVisible,
  });

  const dataSource = useTableDataSource<TRecord>({
    dataSource: dataSourceProp,
    rowKey,
    groupingConfig,
  });

  const columns = useTableColumns<TRecord>({
    columns: columnsProp,
    sorters,
    multipleSorter,
    filters,
    onFilterChange,
    groupingConfig,
  });

  const rowSelection = useTableRowSelection<TRecord>({
    rowSelection: rowSelectionProp,
    initialDataSource: dataSourceProp,
    groupingConfig,
  });

  const expandable = useTableExpandable<TRecord>({
    dataSource,
    expandable: expandableProp,
  });

  const editable = useEditableCells<TRecord>({
    columns,
    editable: editableProp,
    components: componentsProp,
    handleSave,
  });

  const getTableRowClassNames = (record: TRecord, index: number, indent: number) => {
    const tableRowClassNames = getRowClassNames(index, rest.onRow);
    const passedRowClassNames = isFunction(rowClassName) ? rowClassName(record, index, indent) : rowClassName;

    return cn(tableRowClassNames, passedRowClassNames);
  };

  const classNames = getClassNames(!dataSource.length, className);
  const tablePagination = paginationVisible && !isempty(pagination) ? pagination : false;

  return (
    <Table className={classNames} rowClassName={getTableRowClassNames}
           dataSource={dataSource} columns={editable.columns as any}
           onChange={onTableChange}
           pagination={tablePagination}
           rowSelection={rowSelection}
           showSorterTooltip={showSorterTooltip} scroll={scroll}
           expandable={expandable}
           bordered={bordered}
           components={editable.components}
           {...rest} ref={ref}/>
  );
};

export const AppTable = forwardRef(TableComponent) as <TRecord extends AppTableItem>(
  props: AppTableProps<TRecord> & {ref?: ForwardedRef<HTMLDivElement>}
) => ReturnType<typeof TableComponent>;