import {createSlice} from "@reduxjs/toolkit";
import type {OrdersState} from "./types";
import {getOrdersReducerBuilder} from "./reducers";

const initialState: OrdersState = {
  orders: [],
  loading: false,
  total: 0,
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getOrdersReducerBuilder(builder);
  }
});

export const ordersReducer = ordersSlice.reducer;



