import {AppForm, AppFormItem, formRules} from "shared/UI/UIKit/Form";
import {AppInput} from "shared/UI/UIKit/Input";
import {AppDatePicker} from "shared/UI/UIKit/DatePicker";
import {AppButton} from "shared/UI/UIKit/Button";
import {AppFlex} from "shared/UI/UIKit/Flex";
import type {FormInstance} from "antd";
import type {PrescriptionOrderFormValues} from "./types";
import styles from "./styles.module.scss";

type PrescriptionFormProps = {
  form: FormInstance<PrescriptionOrderFormValues>;
  loading: boolean;
  submit: () => void;
}

export const PrescriptionForm = (props: PrescriptionFormProps) => {
  return (
    <AppFlex unit="40" className={styles.form}>
      <div className={styles.header}>Введите личные данные и номер рецепта</div>
      <AppForm form={props.form}>
        <AppFormItem name="name" rules={formRules.required}>
          <AppInput placeholder="ФИО"/>
        </AppFormItem>
        <AppFormItem name="dateOfBirth" rules={formRules.requiredDate}>
          <AppDatePicker placeholder="Дата рождения"/>
        </AppFormItem>
        <AppFormItem name="prescriptionNumber" rules={formRules.required}>
          <AppInput placeholder="Номер рецепта"/>
        </AppFormItem>
      </AppForm>
      <AppButton className={styles.submit} loading={props.loading}
                 onClick={props.submit} size="large">
        Отправить
      </AppButton>
    </AppFlex>
  );
};