import cn from "classnames";
import type {AppTableProps, AppTableItem} from "./types/table.types";
import type {AppTableColumn, AppTableGroupColumn, AppTableSingleColumn} from "./types/column.types";
import type {AppTableGroupingByParentKey, AppTableGroupingByDataIndexes} from "./types/group.types";
import styles from "./Table.module.scss";

export const getClassNames = (isEmpty: boolean, className?: string) => {
  return cn(
    isEmpty && styles["table--empty"],
    className,
  );
};

export const getRowClassNames = <TRecord extends AppTableItem>(
  rowIndex: number,
  onRow: AppTableProps<TRecord>["onRow"],
) => {
  return cn(
    rowIndex % 2 !== 0 && styles["table-row--odd"],
    onRow && styles["table-row--clickable"],
  );
};

export const isTableColumnGroup = <TRecord extends AppTableItem>(
  column: AppTableColumn<TRecord>
): column is AppTableGroupColumn<TRecord> => {
  return "children" in column;
};

export const isTableColumnSingle = <TRecord extends AppTableItem>(
  column: AppTableColumn<TRecord>
): column is AppTableSingleColumn<TRecord> => {
  return !isTableColumnGroup(column);
};

export const isGroupingByParentKey = <TRecord extends AppTableItem>(
  groupingConfig: Exclude<AppTableProps<TRecord>["groupingConfig"], undefined>,
): groupingConfig is AppTableGroupingByParentKey<TRecord> => {
  return "parentKey" in groupingConfig;
};

export const isGroupingByDataIndexes = <TRecord extends AppTableItem>(
  groupingConfig: Exclude<AppTableProps<TRecord>["groupingConfig"], undefined>,
): groupingConfig is AppTableGroupingByDataIndexes => {
  return "dataIndexes" in groupingConfig;
};