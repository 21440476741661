import {AppModal} from "shared/UI/UIKit/Modal";
import {AppButton} from "shared/UI/UIKit/Button";
import type {ChooseMedicineModalProps} from "./types";
import {ChooseMedicineTable} from "./ChooseMedicineTable/ChooseMedicineTable";
import {useChooseMedicineModal} from "./use-choose-medicine-modal";

export const ChooseMedicineModal = (props: ChooseMedicineModalProps) => {
  const {
    addSelectedMedicineToOrderItem,
    selectionService,
    selectedOrderItem,
  } = useChooseMedicineModal(props);

  const footer = (
    <AppButton onClick={addSelectedMedicineToOrderItem}>
      Выбрать
    </AppButton>
  );

  return (
    <AppModal content={<ChooseMedicineTable selectionService={selectionService}/>}
              destroyOnClose={true}
              onCancel={props.hide} size="fullscreen"
              title={`Препараты содержащие: ${selectedOrderItem?.innName}`}
              visible={props.visible} footer={footer}/>
  );
};