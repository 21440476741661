import type {AppSelectOption} from "shared/UI/UIKit/Select";
import type {AppFilterType} from "../../../types";
import {AppFilterOperator} from "../../../constants";

const TEXT_OPERATOR_OPTIONS: AppSelectOption[] = [
  {
    label: "Содержит",
    value: AppFilterOperator.CONTAINS,
  },
  // {
  //   label: "Не содержит",
  //   value: AppFilterOperator.DOES_NOT_CONTAIN,
  // },
  // {
  //   label: "Начинается с",
  //   value: AppFilterOperator.STARTS_WITH,
  // },
  // {
  //   label: "Заканчивается на",
  //   value: AppFilterOperator.ENDS_WITH,
  // },
  // {
  //   label: "Соответствует",
  //   value: AppFilterOperator.EQUAL,
  // },
  // {
  //   label: "Отсутствует",
  //   value: AppFilterOperator.IS_NULL,
  // },
];

const NUMBER_OPERATOR_OPTIONS: AppSelectOption[] = [
  {
    label: "Равно",
    value: AppFilterOperator.EQUAL,
  },
  // {
  //   label: "Не равно",
  //   value: AppFilterOperator.NOT_EQUAL,
  // },
  // {
  //   label: "Больше",
  //   value: AppFilterOperator.MORE,
  // },
  // {
  //   label: "Меньше",
  //   value: AppFilterOperator.LESS,
  // },
  // {
  //   label: "Меньше или равно",
  //   value: AppFilterOperator.LESS_OR_EQUAL,
  // },
  // {
  //   label: "Больше или равно",
  //   value: AppFilterOperator.MORE_OR_EQUAL,
  // },
  // {
  //   label: "В интервале",
  //   value: AppFilterOperator.INTERVAL,
  // },
  // {
  //   label: "Отсутствует",
  //   value: AppFilterOperator.IS_NULL,
  // },
];

const DATE_OPERATOR_OPTIONS: AppSelectOption[] = [
  {
    label: "Соответствует",
    value: AppFilterOperator.EQUAL,
  },
  {
    label: "Не соответствует",
    value: AppFilterOperator.NOT_EQUAL,
  },
  {
    label: "Раньше",
    value: AppFilterOperator.LESS,
  },
  {
    label: "Позже",
    value: AppFilterOperator.MORE,
  },
  {
    label: "В интервале",
    value: AppFilterOperator.INTERVAL,
  },
  {
    label: "Отсутствует",
    value: AppFilterOperator.IS_NULL,
  },
];

const ENUM_OPERATOR_OPTIONS: AppSelectOption[] = [
  {
    label: "Соответствует",
    value: AppFilterOperator.EQUAL,
  },
  // {
  //   label: "Не соответствует",
  //   value: AppFilterOperator.NOT_EQUAL,
  // },
  // {
  //   label: "Отсутствует",
  //   value: AppFilterOperator.IS_NULL,
  // },
];

export const getOperatorOptions = (type: AppFilterType): AppSelectOption[] => {
  switch (type) {
    case "string": {
      return TEXT_OPERATOR_OPTIONS;
    }
    case "number": {
      return NUMBER_OPERATOR_OPTIONS;
    }
    case "time":
    case "date": {
      return DATE_OPERATOR_OPTIONS;
    }
    case "enum": {
      return ENUM_OPERATOR_OPTIONS;
    }
  }
};