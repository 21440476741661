import cn from "classnames";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppTitle} from "shared/UI/UIKit/Title";
import type {AppContentProps} from "./Content.types";
import styles from "./Content.module.scss";

export const AppContent = (props: AppContentProps) => {
  return (
    <main className={cn(styles.content, props.className)}>
      <AppFlex unit="40" className={styles.flex}>
        {
          props.pageTitle && (
            <AppTitle level={1}>{props.pageTitle}</AppTitle>
          )
        }
        {props.children}
      </AppFlex>
    </main>
  );
};
