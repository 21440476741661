import {getDataIndexedColumns, getDefaultTablePagination} from "shared/UI/UIKit/Table";
import type {TableStateManagerState} from "shared/UI/UIKit/Table";
import {columns} from "./columns";

export const initialTableStateManagerState: TableStateManagerState = {
  sorters: getDataIndexedColumns(columns)
      .filter(({key}) => !["№"].includes(key))
      .map(({key, dataIndex}) => {
        return {
          key,
          dataIndex,
        };
      }),
  filters: getDataIndexedColumns(columns)
      .filter(({key}) => ["phone", "address", "status"].includes(key))
      .map(({key, dataIndex}) => {

        const getPlaceholder = () => {
          switch (key) {
            case "status": {
              return "Статус";
            }
            case "address": {
              return "Адрес";
            }
            case "phone": {
              return "Телефон";
            }
          }
        };

        const placeholder = getPlaceholder();

        return {
          key,
          dataIndex,
          type: "string",
          options: {
            placeholder: placeholder,
          },
        };
      }),
  pagination: getDefaultTablePagination(),
};