import {Outlet} from "react-router-dom";
import {AppFlex} from "shared/UI/UIKit/Flex";
import type {AppLayoutProps} from "./Layout.types";
import {AppHeader} from "./Header";
import styles from "./Layout.module.scss";
import {AppFooter} from "./Footer";

export const AppLayout = ({
  visibleHeader = true,
  visibleFooter = true,
}: AppLayoutProps) => {

  return (
    <AppFlex direction="row" unit="0">
      <AppFlex unit="0" alignItems="stretch" className={styles.container}>
        {visibleHeader && <AppHeader/>}
        <Outlet/>
        {visibleFooter && <AppFooter/>}
      </AppFlex>
    </AppFlex>
  );
};