import {errorMessages} from "../../error-messages";

export const arrayValidator = <T = unknown>(required = true, message = errorMessages.requiredArray) => {
  return (_: any, value: T[] | null | undefined) => {
    if (!value || !value.length) {
      return required ? Promise.reject(message) : Promise.resolve();
    }

    return Promise.resolve();
  };
};