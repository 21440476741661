import {AppTitle} from "shared/UI/UIKit/Title";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {createHTMLMarkup} from "shared/helpers/utils/createHtmlMarkup";
import {AppShadowNode} from "shared/UI/UIKit/ShadowNode";

type MedicineInfoItemProps = {
  title: string;
  value?: string;
}

export const MedicineInfoItem = (props: MedicineInfoItemProps) => {
  return (
    <AppFlex unit="10">
      <AppTitle>{props.title}</AppTitle>
      <AppShadowNode>
        <div dangerouslySetInnerHTML={createHTMLMarkup(props.value || "")}/>
      </AppShadowNode>
    </AppFlex>
  );
};

