export const appPagePaths = {
  root: "/",
  home: "/",
  prescriptionOrder: "/prescription-order",
  apothecary: "/apothecary",
  apothecaryOrder: "/apothecary/:id",
  about: "/about",
  termsOfUse: "/terms-of-use",
  termsOfSale: "/terms-of-sale",
  privacyPolicy: "/privacy-policy",
  loadOrderByGuid: "/buy/recipe"
};

export const appRouterPaths = {
  root: "/",
  home: "/",
  prescriptionOrder: "/prescription-order",
  apothecary: "/apothecary",
  apothecaryOrder: "/apothecary/:id",
  about: "/about",
  termsOfUse: "/terms-of-use",
  termsOfSale: "/terms-of-sale",
  privacyPolicy: "/privacy-policy",
  loadOrderByGuid: "/buy/recipe"
};


