import {AppTable} from "shared/UI/UIKit/Table";
import type {ChooseMedicineTableProps} from "./types";
import {columns} from "./columns";
import {useMedicineTable} from "./use-medicine-table";

export const ChooseMedicineTable = (props: ChooseMedicineTableProps) => {
  const {state, methods} = useMedicineTable(props);

  return (
    <AppTable rowKey={(value) => value.id}
              loading={state.loading} bordered columns={columns}
              dataSource={state.medicines}
              expandable={methods.getExpandableProps()}
              rowSelection={methods.getRowSelection()}
    />
  );
};