import {useModal} from "shared/UI/UIKit/Modal";
import {AppMenuOutlinedIcon} from "shared/UI/UIKit/icons/Menu";
import {useProfile} from "common/hooks/use-profile";
import {AppButton} from "shared/UI/UIKit/Button";
import {MobileNavSider} from "./NavigationSider/NavigationSider";

export const MobileHeaderMenu = () => {
  const {state, methods} = useModal();
  const {profile, login} = useProfile();
  const openNav = () => {
    methods.show();
  };

  const closeNav = () => {
    methods.hide();
  };

  return (
    <>
      {
        profile ?
          <AppMenuOutlinedIcon onClick={openNav}/> :
          <AppButton onClick={() => login()} size="large">
            Войти
          </AppButton>
      }
      <MobileNavSider hide={closeNav} visible={state.visible}/>
    </>
  );
};