import dayjs from "dayjs";
import {BACK_END_TIME_FORMAT} from "shared/constants/date";
import type {AppFilter} from "../../../types";
import type {FilterFormValues} from "../../types";
import type {TimeFilterFormValue} from "./types";
import {AppFilterOperator} from "../../../constants";

const DEFAULT_OPERATOR = AppFilterOperator.EQUAL;
const TIME_FORMAT = BACK_END_TIME_FORMAT;

export const getInitialFormValues = (currentFilter: AppFilter): FilterFormValues<TimeFilterFormValue> => ({
  operator: currentFilter.operator ?? DEFAULT_OPERATOR,
  values: (currentFilter.values ?? []).map(value => {
    if (value === null || value === undefined) {
      return value;
    }

    return dayjs(value, TIME_FORMAT);
  }),
});

export const getFilterToSubmit = (
  currentFilter: AppFilter, formValues: FilterFormValues<TimeFilterFormValue>
): AppFilter => ({
  ...currentFilter,
  operator: formValues.operator,
  /*
    formValues.values will be undefined when the form has no form items.
    This is possible when operator === APP_FILTER_OPERATORS.IS_NULL (cause the only form item is not rendering)
    Thus, to detect a situation when there are no values, but the filter is set, we set values as list with null element
   */
  values: (formValues.values ?? [null]).map(value => {
    if (value === null || value === undefined) {
      return value;
    }

    return value.format(TIME_FORMAT);
  })
});

export const getFilterToReset = (currentFilter: AppFilter): AppFilter => ({
  ...currentFilter,
  operator: DEFAULT_OPERATOR,
  values: [],
});