import {useEffect, useRef} from "react";

export const useDidMountEffect = (func: React.EffectCallback , deps?: React.DependencyList | undefined) => {
  const didMount = useRef<boolean>(false);

  useEffect(() => {
    if (!didMount.current) {
      func();
      didMount.current = true;
    }
  }, deps);
};