import {AppPage} from "common/components/Layout";
import {StaticContent} from "features/StaticContent/StaticContent";
import {items} from "./items";

export const TermsOfSalePage = () => {
  return (
    <AppPage pageTitle="Условия продажи">
      <StaticContent items={items}/>
    </AppPage>

  );
};