import type {ActionReducerMapBuilder} from "@reduxjs/toolkit";
import type {PrescriptionOrderState} from "./types";
import {
  createPrescriptionOrderAction,
  loadMedicineByInnNameAction,
  loadPrescriptionOrderAction,
  loadPrescriptionOrderByGuidAction
} from "./actions";

export const getPrescriptionOrderReducerBuilder = (builder: ActionReducerMapBuilder<PrescriptionOrderState>) => {
  builder.addCase(loadPrescriptionOrderAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(loadPrescriptionOrderByGuidAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(loadPrescriptionOrderAction.fulfilled, (state, action) => {
    state.loading = false;
    state.prescriptionOrder = action.payload;
  });
  builder.addCase(loadPrescriptionOrderByGuidAction.fulfilled, (state, action) => {
    state.loading = false;
    state.prescriptionOrder = action.payload;
  });
  builder.addCase(loadPrescriptionOrderAction.rejected, (state) => {
    state.loading = false;
  });
  builder.addCase(loadPrescriptionOrderByGuidAction.rejected, (state) => {
    state.loading = false;
  });
};


export const getMedicineByInnNameReducerBuilder = (builder: ActionReducerMapBuilder<PrescriptionOrderState>) => {
  builder.addCase(loadMedicineByInnNameAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(loadMedicineByInnNameAction.fulfilled, (state, action) => {
    state.loading = false;
    state.medicines = action.payload;
  });
  builder.addCase(loadMedicineByInnNameAction.rejected, (state) => {
    state.loading = false;
  });
};

export const getCreatePrescriptionOrderBuilder = (builder: ActionReducerMapBuilder<PrescriptionOrderState>) => {
  builder.addCase(createPrescriptionOrderAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(createPrescriptionOrderAction.fulfilled, (state, action) => {
    state.loading = false;
    state.prescriptionOrder = action.payload;
  });
  builder.addCase(createPrescriptionOrderAction.rejected, (state) => {
    state.loading = false;
  });
};