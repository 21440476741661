import {useEffect} from "react";
import {useSearchParams,useNavigate} from "react-router-dom";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {ACCESS_TOKEN_NAME} from "shared/constants/api";
import {selectProfile} from "../redux/selectors";
import {getProfileAction} from "../redux/actions";

export const useInitProfile = () => {
  const dispatch = useAppDispatch();
  const {loading} = useAppSelector(selectProfile);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const access_token = searchParams.get("access_token");

  useEffect(() => {
    if (access_token) {
      localStorage.setItem(ACCESS_TOKEN_NAME, access_token);
      searchParams.delete("access_token");
      searchParams.delete("token_type");
      searchParams.delete("expires_in");
      searchParams.delete("scope");
      searchParams.delete("state");
      navigate(location.pathname + "?" + searchParams.toString(), {replace: true});
      setSearchParams(searchParams);
    }
    dispatch(getProfileAction());
  }, []);

  return {
    loading
  };
};
