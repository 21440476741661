import {createSlice} from "@reduxjs/toolkit";
import type {OrderState} from "./types";
import {
  getOrderByIdReducerBuilder,
  setOrderCanceledBuilder,
  setOrderInDeliveryBuilder,
  setOrderReadyBuilder
} from "./reducers";

const initialState: OrderState = {
  order: null,
  loading: false,
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getOrderByIdReducerBuilder(builder);
    setOrderCanceledBuilder(builder);
    setOrderInDeliveryBuilder(builder);
    setOrderReadyBuilder(builder);
  }
});

export const orderReducer = orderSlice.reducer;
