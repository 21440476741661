export const DEFAULT_TITLE = "ГБУЗ «ЦЛО ДЗМ». Аптеки столицы.";
export const POSTFIX = "";
export const ELLIPSIS = "...";
const DOCUMENT_TITLE_LIMIT = 60;

export const getDocumentTitle = (title?: string | null, withPostfix = true): string => {
  if (!title) {
    return DEFAULT_TITLE;
  }

  const postfix = withPostfix ? POSTFIX : "";
  const postfixLength = withPostfix ? POSTFIX.length : 0;

  if (title.length + postfixLength <= DOCUMENT_TITLE_LIMIT) {
    return `${title}${postfix}`;
  }

  const maxTitleLength = DOCUMENT_TITLE_LIMIT - ELLIPSIS.length - postfixLength;
  const limitedTitle = `${title.substring(0, maxTitleLength)}`;
  return `${limitedTitle}${ELLIPSIS}${postfix}`;
};